import React from "react";
import { useIsMobile } from "../../useIsMobile";
import "./FullButton.scss";

export default function FullButton({ text, onClick, className, disabled }) {
  const isMobile = useIsMobile();

  return (
    <button
      onClick={onClick}
      className={`fullButtonWrapper ${className}`}
      disabled={disabled}
    >
      {disabled && <span style={{ opacity: 1 }}>{text}</span>}
      {!disabled && (
        <>
          <span>{text}</span>

          <div class="marquee" aria-hidden="true">
            <div class="marquee__inner">
              <span>{text}</span>
              <span>{text}</span>
              <span>{text}</span>
              <span>{text}</span>
            </div>
          </div>
        </>
      )}
    </button>
  );
}
