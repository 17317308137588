import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translation files
import en from "./locale/en.json";
import sp from "./locale/sp.json";

const savedLanguage = localStorage.getItem("i18nextLng") || "en";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    sp: { translation: sp },
  },
  lng: savedLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
