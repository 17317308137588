import React from "react";
import "./Flag.scss";

export default function Flag({ source, flag, onClick, isActive }) {
  return (
    <div className={`flagWrapper ${isActive ? "active" : ""}`} onClick={onClick}>
      <img src={source} alt={flag} />
      <p>{flag}</p>
    </div>
  );
}
