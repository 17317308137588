import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import "./OnScrollTypography.scss";
import FullButton from "../Button/FullButton/FullButton";
import { useIsMobile } from "../../components/useIsMobile";
import video from "../../assets/video/arrows.webm";

gsap.registerPlugin(ScrollTrigger);

const OnScrollTypography = ({
  smallTitle,
  title,
  description,
  text,
  desktopStyle,
  className,
  onClick,
  haveAnimation = true,
}) => {
  const isMobile = useIsMobile();
  useEffect(() => {
    Splitting();

    const fx2Titles = [
      ...document.querySelectorAll(".content__title[data-effect2]"),
    ];
    const paragraphs = [
      ...document.querySelectorAll(".content__text[data-effect2]"),
    ];
    const fx2SmallTitle = [
      ...document.querySelectorAll(".content__small[data-effect2]"),
    ];

    const allChars = document.querySelectorAll(".splitting .char");
    allChars.forEach((char) => {
      if (char.closest(".content__title")) {
        char.style.fontSize = isMobile ? "32px" : "50px";
      } else if (char.closest(".content__text")) {
        char.style.fontSize = isMobile ? "18px" : "20px";
      } else if (char.closest(".content__small")) {
        char.style.fontSize = isMobile ? "22px" : "28px";
      }
    });

    const tl = gsap.timeline();

    fx2Titles.forEach((title) => {
      const chars = title.querySelectorAll(".char");

      tl.fromTo(
        chars,
        {
          opacity: 0,
          yPercent: 120,
          scaleY: 2,
          scaleX: 0.9,
          transformOrigin: "50% 0%",
        },
        {
          duration: 0.3,
          opacity: 1,
          yPercent: 0,
          scaleY: 1,
          scaleX: 1,
          stagger: 0.05,
          scrollTrigger: {
            trigger: title,
            start: "top 80%",
            end: "bottom 40%",
            scrub: true,
          },
        }
      );
    });

    paragraphs.forEach((p) => {
      const chars = p.querySelectorAll(".char");

      tl.fromTo(
        chars,
        {
          opacity: 0,
          yPercent: 120,
          scaleY: 2,
          scaleX: 0.9,
          transformOrigin: "50% 0%",
        },
        {
          duration: 0.3,
          opacity: 1,
          yPercent: 0,
          scaleY: 1,
          scaleX: 1,
          stagger: 0.05,
          scrollTrigger: {
            trigger: p,
            start: "top 80%",
            end: "bottom 40%",
            scrub: true,
          },
        }
      );
    });

    fx2SmallTitle.forEach((small) => {
      const chars = small.querySelectorAll(".char");

      tl.fromTo(
        chars,
        {
          opacity: 0,
          yPercent: 120,
          scaleY: 2,
          scaleX: 0.9,
          transformOrigin: "50% 0%",
        },
        {
          duration: 0.3,
          opacity: 1,
          yPercent: 0,
          scaleY: 1,
          scaleX: 1,
          stagger: 0.05,
          scrollTrigger: {
            trigger: small,
            start: "top 80%",
            end: "bottom 40%",
            scrub: true,
          },
        }
      );
    });
  }, []);

  return (
    <div>
      {haveAnimation && !isMobile && (
        <video
          style={{
            width: "500px",
            transform: "rotate(180deg)",
            position: "absolute",
            top: 0,
            left: "-80px",
            zIndex: -1,
          }}
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={video} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      )}
      <div className={`content ${desktopStyle}`}>
        {smallTitle && (
          <h4
            className="content__small"
            data-splitting
            data-effect2
            style={{
              color: "#BD88FF",
            }}
          >
            {smallTitle}
          </h4>
        )}

        {title && (
          <h2 className="content__title" data-splitting data-effect2>
            <span className="font-2">{title}</span>
          </h2>
        )}

        {description && (
          <p className="content__text" data-splitting data-effect2>
            {description}
          </p>
        )}

        {text && (
          <FullButton text={text} className={className} onClick={onClick} />
        )}
      </div>

      {haveAnimation && !isMobile && (
        <video
          style={{
            width: "500px",
            position: "absolute",
            top: 0,
            right: "0px",
            zIndex: -1,
          }}
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={video} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export default OnScrollTypography;
