import React, { useEffect } from "react";
import "./Success.scss";
import Check from "../../../assets/images/icons/check.svg";
import { useTranslation } from "react-i18next";
import ModalDeskopt from "../../../assets/images/desktopModal1.svg";
import ModalMobile from "../../../assets/images/mobileModal1.svg";
import { useIsMobile } from "../../useIsMobile";

export default function Success({ setOpenSuccess }) {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSuccess(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [setOpenSuccess]);

  return (
    <div className="successOverlay">
      {isMobile ? (
        <img src={ModalMobile} alt={ModalMobile} className="gloss" />
      ) : (
        <img src={ModalDeskopt} alt={ModalDeskopt} className="gloss" />
      )}
      <div className="successContent">
        <img src={Check} alt={Check} />
        <h2>{t("success.title")}</h2>
      </div>
    </div>
  );
}
