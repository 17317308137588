import React, { useState, useEffect, useRef } from "react";
import "./PlayerCard.scss";
import Pattern from "../../../assets/images/pattern.svg";
import Shadow from "../../../assets/images/Shadow.svg";
import PlayerShadow from "../../../assets/images/playerShadow.svg";
import Tags from "../../../components/Tags/Tags";
import { usePlayersData } from "../../../data/players";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { useIsMobile } from "../../useIsMobile";
import { useIsTablet } from "../../useIsTablet";
import { Slide } from "react-awesome-reveal";

export default function PlayerCard() {
  const [expandedCardIndex, setExpandedCardIndex] = useState(null);
  const swiperRef = useRef(null);
  const players = usePlayersData();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const cardRefs = useRef([]);
  const [slidesPerView, setSlidesPerView] = useState("auto");
  const [gap, setGap] = useState(10);

  useEffect(() => {
    function handleResize() {
      const cardWidth = 220; // Width of each card in px
      const availableWidth = window.innerWidth; // Account for any padding/margin
      const totalGap = gap * (Math.floor(availableWidth / cardWidth) - 1); // Total space for gaps

      let numCards = Math.floor(
        (availableWidth - totalGap) / (cardWidth + gap)
      ); // Calculate how many cards can fit

      if (isMobile) {
        numCards = 1.8;
      }

      setSlidesPerView(numCards); // Set the number of slides per view
    }

    handleResize(); // Call it initially to set the value
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [gap]);

  console.log(slidesPerView, "slidesPerView");

  const handleCardClick = (index) => {
    const swiper = swiperRef.current;
    const isCurrentlyExpanded = expandedCardIndex === index ? null : index;
    setExpandedCardIndex(isCurrentlyExpanded);

    if (swiper) {
      swiper.autoplay?.stop(); // Stop autoplay when expanding a card

      // Get the real Swiper index (important in loop mode)
      const realIndex = swiper.realIndex; // Swiper's current active index
      const clickedSlideIndex = swiper.slides[index].dataset.swiperSlideIndex;

      // Check if the clicked slide is different from the real index
      if (realIndex !== parseInt(clickedSlideIndex)) {
        setTimeout(() => {
          swiper.slideToLoop(index, 500, false); // Move smoothly
        }, 50);
      }
    }
  };

  return (
    <div className="playerCardWrapper">
      <Swiper
        loop={true}
        slidesPerView={slidesPerView}
        spaceBetween={gap}
        navigation
        // autoplay={{ delay: 7000 }}
        modules={[Navigation, Pagination, Autoplay]}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {players.map((card, index) => (
          <SwiperSlide
            key={index}
            className={`swiper-slide ${
              expandedCardIndex === index ? "expanded-slide" : ""
            }`}
          >
            <Slide
              direction="down"
              triggerOnce={true}
              fraction={0.5}
              delay={index * 50}
            >
              <div
                className={`playerCard ${
                  expandedCardIndex === index ? "expanded" : ""
                }`}
                // onClick={() => handleCardClick(index)}
                ref={(el) => (cardRefs.current[index] = el)}
                tabIndex="0"
                // onFocus={() => swiperRef.current?.slideTo(index)}
                role="button"
                aria-expanded={expandedCardIndex === index}
              >
                {expandedCardIndex !== index && (
                  <>
                    <div
                      className="playerBackground"
                      style={{
                        background: `url(${Pattern})`,
                      }}
                    >
                      <img
                        className="playerImage"
                        src={card.image}
                        alt={card.image}
                      />

                      <img src={card.logo} alt={card.logo} className="logo" />

                      <img
                        src={PlayerShadow}
                        alt={PlayerShadow}
                        className="shadow"
                      />
                      <div className="playerName">
                        <h2>{card.name}</h2>
                        <h1>{card.surname}</h1>
                      </div>
                    </div>
                    {/* <div className="playerInfo">
                      <h2>{card.name}</h2>
                      <h2>{card.surname}</h2>
                    </div> */}
                  </>
                )}

                {expandedCardIndex === index && (
                  <div className="expandedContent">
                    <img
                      src={card.background}
                      alt={card.background}
                      className="expandedContentBackground"
                    />
                    <img src={Shadow} alt={Shadow} className="shadow" />
                    <div className="content">
                      <h1>
                        {card.name} {card.surname}
                      </h1>
                      <div className="tagsWrapp">
                        {card.tags.map((item, tagIndex) => (
                          <Tags
                            key={tagIndex}
                            color={"#BD88FF"}
                            backgroundColor={"#BD88FF4C"}
                            title={item.name}
                            number={item.number}
                          />
                        ))}
                      </div>
                      <div className="infoWrapp">
                        <p>{card.description1}</p>
                      </div>
                    </div>
                    <img src={card.logo} alt={card.logo} className="logo" />
                  </div>
                )}
              </div>
            </Slide>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
