import React, { useState } from "react";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/icons/logo.svg";
import Facebook from "../../assets/images/icons/facebook.svg";
import Youtube from "../../assets/images/icons/video.svg";
import Instagram from "../../assets/images/icons/instagram.svg";
import Arrow from "../../assets/images/icons/ic_arrow_white.svg";
import { useIsMobile } from "../../components/useIsMobile";
import { Link } from "react-router-dom";
import Offer from "../../components/Modal/Offer/Offer";
import Success from "../../components/Modal/Success/Success";
import axios from "axios";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();
  const [email, setEmail] = useState("");
  const [openOffer, setOpenOffer] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleNewsletter = () => {
    axios
      .post("https://stage.skillset.tv/api/newsletter/email/verify", {
        email: email,
      })
      .then((response) => {
        setOpenOffer(true);
        localStorage.setItem("newsletter", response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      {isMobile ? (
        <div className="footerWrapper">
          <h2>{t("footer.title")}</h2>
          <div className="newsletter">
            <input
              placeholder={t("footer.enterAddress")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={() => handleNewsletter()}>
              {t("footer.send")} <img src={Arrow} alt={Arrow} />
            </button>
          </div>

          <div className="middleArea">
            <img src={Logo} alt={Logo} className="logo" />
            <p>{t("footer.startToday")}</p>
            <div className="social">
              {/* <a href="/">
                <img src={Facebook} alt={Facebook} />
              </a>
              <a href="/">
                <img src={Youtube} alt={Youtube} />
              </a> */}
              <a
                href="https://www.instagram.com/skillset.tv/?igsh=cjZrc292Nzd4ZThs"
                target="_blank"
              >
                <img src={Instagram} alt={Instagram} />
              </a>
            </div>

            <div className="quickLinks">
              <h3>{t("footer.quickLinks")}</h3>
              <ul>
                <li>
                  <a href="/#pro-players">{t("navigation.proPlayers")}</a>
                </li>
                <li>
                  <a href="/#exclusiveContent">
                    {t("navigation.exclusiveContent")}
                  </a>
                </li>
                <li>
                  <a href="/#aboutApp">{t("navigation.aboutTheApp")}</a>
                </li>
                <li>
                  <a href="/#challenges">{t("navigation.challenges")}</a>
                </li>
                <li>
                  <Link to="/privacy">{t("navigation.privacy")}</Link>
                </li>
                <li>
                  <Link to="terms">{t("navigation.terms")}</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="copyright">
            <p>{t("footer.copy")}</p>
            <a href="mailto: support@skillset.tv">support@skillset.tv</a>
            <p>
              {t("footer.developed")}{" "}
              <a href="https://qwertify.io/" target="_blank">
                Qwertify
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div className="footerWrapper">
          <div className="desktopFooterWrapper">
            <h2>{t("footer.title")}</h2>
            <div className="newsletter">
              <input
                placeholder={t("footer.enterAddress")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button onClick={() => handleNewsletter()}>
                {t("footer.send")} <img src={Arrow} alt={Arrow} />
              </button>
            </div>
          </div>

          <div className="desktopMiddleArea">
            <div className="desktopFooterContent">
              <img src={Logo} alt={Logo} className="logo" />
              <p>{t("footer.startToday")}</p>
              <div className="social">
                {/* <a href="/">
                  <img src={Facebook} alt={Facebook} />
                </a>
                <a href="/">
                  <img src={Youtube} alt={Youtube} />
                </a> */}
                <a
                  href="https://www.instagram.com/skillset.tv/?igsh=cjZrc292Nzd4ZThs"
                  target="_blank"
                >
                  <img src={Instagram} alt={Instagram} />
                </a>
              </div>
            </div>
            <div className="quickLinks">
              <h3>{t("footer.quickLinks")}</h3>
              <ul>
                <li>
                  <a href="/#pro-players">{t("navigation.proPlayers")}</a>
                </li>
                <li>
                  <a href="/#exclusiveContent">
                    {t("navigation.exclusiveContent")}
                  </a>
                </li>
                <li>
                  <a href="/#aboutApp">{t("navigation.aboutTheApp")}</a>
                </li>
                <li>
                  <a href="/#challenges">{t("navigation.challenges")}</a>
                </li>
                <li>
                  <Link to="/privacy">{t("navigation.privacy")}</Link>
                </li>
                <li>
                  <Link to="terms">{t("navigation.terms")}</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="desktopCopyrigh">
            <p>{t("footer.copy")}</p>
            <a href="mailto: support@skillset.tv">support@skillset.tv</a>
            <p>
              {t("footer.developed")}{" "}
              <a href="https://qwertify.io/" target="_blank">
                Qwertify
              </a>
            </p>
          </div>
        </div>
      )}

      {openOffer && (
        <Offer
          setOpenOffer={setOpenOffer}
          setOpenSuccess={setOpenSuccess}
          onClick={() => setOpenOffer(false)}
        />
      )}
      {openSuccess && <Success setOpenSuccess={setOpenSuccess} />}
    </>
  );
}
