import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./GetApp.scss";

// Images
import Record from "../../assets/images/record.svg";
import Motivation from "../../assets/images/icons/motivation.svg";
import Interaction from "../../assets/images/icons/interaction.svg";
import Progress from "../../assets/images/icons/progress.svg";
import Trophy from "../../assets/images/icons/trophy.svg";
import Video from "../../assets/video/screen_record.webm";
import video from "../../assets/video/arrows.webm";

// Components
import Text from "../../components/Text/Text";
import { useIsMobile } from "../../components/useIsMobile";
import { Slide } from "react-awesome-reveal";
import OnScrollTypography from "../../components/Text/OnScrollTypography";
import JoinWaitlist from "../../components/Modal/JoinWaitlist/JoinWaitlist";
import Offer from "../../components/Modal/Offer/Offer";
import Success from "../../components/Modal/Success/Success";

export default function GetApp() {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();
  const [openJoin, setOpenJoin] = useState(false);
  const [openOffer, setOpenOffer] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleGetStarted = () => {
    setOpenJoin(true);
  };

  const mobileData = [
    {
      id: 1,
      image: Motivation,
      title: `${t("getApp.motivation")}`,
      description: `${t("getApp.motivationDesc")}`,
    },
    {
      id: 2,
      image: Interaction,
      title: `${t("getApp.interaction")}`,
      description: `${t("getApp.interactionDescription")}`,
    },
    {
      id: 3,
      image: Progress,
      title: `${t("getApp.progress")}`,
      description: `${t("getApp.progressDescription")}`,
    },
    {
      id: 4,
      image: Trophy,
      title: `${t("getApp.trophy")}`,
      description: `${t("getApp.trophyDescription")}`,
    },
  ];

  return (
    <>
      <div className="getAppWrapper" id="challenges">
        {isMobile && (
          <video
            style={{
              width: "300px",
              height: "300px",
              position: "absolute",
              right: 0,
              top: "-120px",
              zIndex: "-1",
            }}
            autoPlay
            muted
            loop
            playsInline
          >
            <source src={video} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        )}
        <OnScrollTypography
          smallTitle={t("getApp.smallTitle")}
          title={t("getApp.title")}
          description={t("getApp.description")}
          text={t("getApp.button")}
          className={"smallButton"}
          desktopStyle={"featureDesktopWrapper"}
          haveAnimation={false}
          onClick={() => handleGetStarted()}
        />
        {isMobile ? (
          <div className="mobileGetAppWrapper">
            <Slide direction="up" cascade triggerOnce>
              <video
                style={{ width: "100%", height: "100%" }}
                autoPlay
                muted
                loop
                playsInline
              >
                <source src={Video} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </Slide>
            <div className="mobileInfoCardWrapper">
              {mobileData.map((item, index) => {
                return (
                  <Slide
                    key={index}
                    direction="up" // Alternate direction
                    cascade
                    triggerOnce
                  >
                    <div className="singleMobileCard">
                      <img src={item.image} alt={item.image} />
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                    </div>
                  </Slide>
                );
              })}
            </div>

            {/* <video
              style={{
                width: "300px",
                position: "absolute",
                transform: "rotate(180deg)",
                left: 0,
                bottom: 0,
                zIndex: "-1",
              }}
              autoPlay
              muted
              loop
              playsInline
            >
              <source src={video} type="video/webm" />
              Your browser does not support the video tag.
            </video> */}
          </div>
        ) : (
          <div className="desktopGetAppWrapper">
            <video
              style={{
                width: "500px",
                position: "absolute",
                top: "-220px",
                right: "20px",
                zIndex: "-1",
              }}
              autoPlay
              muted
              loop
              playsInline
            >
              <source src={video} type="video/webm" />
              Your browser does not support the video tag.
            </video>
            <div className="cardsWrapp">
              <Slide direction="up" cascade triggerOnce>
                {mobileData.slice(0, 2).map((item, index) => {
                  return (
                    <div className="singleDesktopCard">
                      <img src={item.image} alt={item.image} />
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                    </div>
                  );
                })}
              </Slide>
            </div>
            <Slide direction="down" cascade triggerOnce>
              <video
                style={{ width: "423px", height: "100%" }}
                autoPlay
                muted
                loop
                playsInline
              >
                <source src={Video} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </Slide>
            <div className="cardsWrapp">
              <Slide direction="down" cascade triggerOnce>
                {mobileData.slice(2, 4).map((item, index) => {
                  return (
                    <div className="singleDesktopCard">
                      <img src={item.image} alt={item.image} />
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                    </div>
                  );
                })}
              </Slide>
            </div>

            {/* <video
              style={{
                width: "500px",
                transform: "rotate(180deg)",
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: "-1",
              }}
              autoPlay
              muted
              loop
              playsInline
            >
              <source src={video} type="video/webm" />
              Your browser does not support the video tag.
            </video> */}
          </div>
        )}
      </div>

      {openJoin && (
        <JoinWaitlist
          onClick={() => setOpenJoin(false)}
          setOpenJoin={setOpenJoin}
          setOpenOffer={setOpenOffer}
        />
      )}
      {openOffer && (
        <Offer
          setOpenOffer={setOpenOffer}
          setOpenSuccess={setOpenSuccess}
          onClick={() => setOpenOffer(false)}
        />
      )}
      {openSuccess && <Success setOpenSuccess={setOpenSuccess} />}
    </>
  );
}
