import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Arrow from "../../assets/images/dropdownArrow.svg";
import Remove from "../../assets/images/icons/removeChip.svg";
import Shadow from "../../assets/images/chipShadow.svg";
import { useTranslation } from "react-i18next";

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;

  .shadow {
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 99;

    img {
      height: 50px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .shadowClosed {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }
`;

const DropdownHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  border: 2px solid rgb(131, 52, 255);
  border-radius: 25px;
  box-sizing: border-box;
  cursor: pointer;
  min-height: 50px;
  position: relative;

  .shadowInput {
    position: absolute;
    right: 0px;
    z-index: 3;
    height: 40px;
    border-radius: 20px;
  }

  &.openDropdown {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }
`;

const PlaceholderText = styled.span`
  color: #9e9e9eb2;
  font-family: "Medium";
  font-size: 16px;
  position: absolute;
  left: 10px;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 20px;

  input {
    height: 20px;
    padding: 0;
    padding-left: 3px;
  }
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  font-size: 14px;
  padding-bottom: 5px;

  min-width: ${(props) =>
    props.hasSelection ? "auto" : "50px"}; /* Prevent width change */
`;

const SelectedTagsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: thin;
  // scrollbar-color: rgb(131, 52, 255) transparent;
  // max-width: 100%;
  width: 100%;
  padding-top: ${(props) => (props.isOpen ? "5px" : "0")};
  position: relative;
  z-index: 2;
  padding-bottom: 5px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ::-webkit-scrollbar-thumb:horizontal {
    display: none;
  }
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 2px 4px 6px;
  background: #271531;
  color: #9e9e9e;
  border-radius: 11px;
  font-size: 12px;
  margin-right: 5px;
  white-space: nowrap;
  font-family: Regular;

  button {
    background: #0d0014;
    border: 0;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      widht: 8px;
      height: 8px;
    }
  }
`;

const DropdownIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 3;
`;

const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 250px;
  overflow-y: auto;
  border-radius: 25px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: #0d0014;
  border: 2px solid rgb(131, 52, 255);
  z-index: 10;
  border-top: 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListItem = styled.div`
  margin: 0;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.2s ease;
  color: #9e9e9e;
  font-size: 16px;
  font-family: Medium;
  border-top: 0.5px solid;
  border-image: linear-gradient(to right, #8334ff57, #bd88ff, #8334ff57) 0.5;

  &:hover {
    background: #6d28d9;
    margin: 0;
    padding: 10px;
    border-radius: 0;
  }

  &.selected {
    background: #6d28d9;
    margin: 0;
    padding: 10px;
    border-radius: 0;
  }
`;

const MultiSelectDropdown = ({
  options = [],
  placeholder = "Select options...",
  onChange,
  width,
  onSecondChange,
  value,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);
  const { t, i18n } = useTranslation();

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (option) => {
    const isSelected = selectedOptions.some(
      (item) => item.name === option.name
    );
    const updatedOptions = isSelected
      ? selectedOptions.filter((item) => item.name !== option.name)
      : [...selectedOptions, option];

    setSelectedOptions(updatedOptions);
    onChange && onChange(updatedOptions.map((item) => item.name));
  };

  const handleRemove = (option) => {
    const updatedOptions = selectedOptions.filter(
      (item) => item.name !== option.name
    );
    setSelectedOptions(updatedOptions);
    onChange && onChange(updatedOptions.map((item) => item.name));
  };

  const filteredOptions = options.filter((option) =>
    option?.name?.toLowerCase().includes(value?.toLowerCase())
  );

  const handleOpen = () => {
    searchRef.current?.focus();
    if (!isOpen && selectedOptions.length <= 0) {
      toggleDropdown();
    }
  };

  return (
    <DropdownContainer width={width} ref={dropdownRef}>
      <DropdownHeader
        onClick={() => handleOpen()}
        className={isOpen ? "openDropdown" : ""}
      >
        <img src={Shadow} alt={Shadow} className={"shadowInput"} />
        {/* Placeholder only when dropdown is closed and no selections */}
        <PlaceholderText show={!isOpen && selectedOptions.length === 0}>
          {placeholder}
        </PlaceholderText>

        <div className={isOpen ? "shadow" : "shadowClosed"}>
          <img src={Shadow} alt={Shadow} />
        </div>

        {isOpen && (
          <InputContainer isOpen={isOpen}>
            <SearchInput
              ref={searchRef}
              type="text"
              placeholder={t("offer.search")}
              value={value}
              onChange={onSecondChange}
              onFocus={() => setIsOpen(true)}
            />
          </InputContainer>
        )}
        <SelectedTagsContainer isOpen={isOpen}>
          {selectedOptions.map((option) => (
            <Tag key={option.name}>
              <span>{option.name}</span>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemove(option);
                }}
              >
                <img src={Remove} alt={Remove} />
              </button>
            </Tag>
          ))}
        </SelectedTagsContainer>
        <DropdownIcon
          onClick={(e) => {
            e.stopPropagation();
            toggleDropdown();
          }}
        >
          <img
            src={Arrow}
            alt="Dropdown Icon"
            style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }}
          />
        </DropdownIcon>
      </DropdownHeader>
      {isOpen && (
        <DropdownList>
          {filteredOptions.length === 0 ? (
            <ListItem>{t("offer.noOptions")}</ListItem>
          ) : (
            filteredOptions.map((option) => (
              <ListItem
                key={option.name}
                onClick={() => handleSelect(option)}
                className={
                  selectedOptions.some((item) => item.name === option.name)
                    ? "selected"
                    : ""
                }
              >
                {option.name}
              </ListItem>
            ))
          )}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default MultiSelectDropdown;
