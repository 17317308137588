import React from "react";
import "./PlayerCards.scss";
import { useTranslation } from "react-i18next";
import PlayerCard from "../../components/Card/PlayerCard/PlayerCard";
import OnScrollTypography from "../../components/Text/OnScrollTypography";
import video from "../../assets/video/arrows.webm";
import { useIsMobile } from "../../components/useIsMobile";

export default function PlayerCards() {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();

  return (
    <div className="playerCardsWrapper" id="pro-players">
      <div className="playerTitle">
        {isMobile && (
          <video
            style={{
              width: "300px",
              height: "300px",
              position: "absolute",
              right: 0,
              top: "-170px",
              zIndex: "-1",
              backdropFilter: "none",
              backgroundBlendMode: "inherit",
              background: "none",
            }}
            autoPlay
            muted
            loop
            playsInline
          >
            <source src={video} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        )}
        <OnScrollTypography title={t("cards.title")} haveAnimation={false} />
      </div>
      <PlayerCard />
    </div>
  );
}
