import React, { useState } from "react";
import Text from "../../components/Text/Text";
import "./Baner.scss";
import { useTranslation } from "react-i18next";

// Images
import BanerMobile from "../../assets/images/heroImageMobile.png";
import BanerMobileTest from "../../assets/images/heroImageMobileTest.webp";
import BanerDesktop from "../../assets/images/heroImageDesktop.webp";
import BanerTablet from "../../assets/images/heroImageTablet.png";
import { useIsMobile } from "../../components/useIsMobile";
import JoinWaitlist from "../../components/Modal/JoinWaitlist/JoinWaitlist";
import Offer from "../../components/Modal/Offer/Offer";
import Success from "../../components/Modal/Success/Success";
import video from "../../assets/video/arrows.webm";
import { useIsTablet } from "../../components/useIsTablet";

export default function Baner() {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { t, i18n } = useTranslation();
  const [openJoin, setOpenJoin] = useState(false);
  const [openOffer, setOpenOffer] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [newsletterId, setNewsletterId] = useState(null);

  const handleGetStarted = () => {
    setOpenJoin(true);
  };

  return (
    <>
      <div className="bannerWrapper">
        {isMobile && (
          <video
            style={{
              width: "100%",
              height: "300px",
              transform: "rotate(180deg)",
              position: "absolute",
              zIndex: "-1",
              background: "none",
            }}
            autoPlay
            muted
            loop
            playsInline
          >
            <source src={video} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        )}
        <img
          src={
            isMobile && !isTablet
              ? BanerMobileTest
              : isTablet
              ? BanerTablet
              : BanerDesktop
          }
          alt={BanerMobile}
          className="banerImage"
        />

        <div className="banerContentWrapper">
          <Text
            bigTitle
            smallTitle={t("baner.smallTitle")}
            title={t("baner.title")}
            description={t("baner.description")}
            text={t("navigation.getStarted")}
            className={"smallButton"}
            onClick={() => handleGetStarted()}
          />

          {!isMobile && (
            <div className="videoWrapper">
              <video
                style={{
                  width: "100%",
                  height: "300px",
                  transform: "rotate(180deg)",
                }}
                autoPlay
                muted
                loop
                playsInline
              >
                <source src={video} type="video/webm" />
                Your browser does not support the video tag.
              </video>
              <video
                style={{ width: "100%", height: "300px" }}
                autoPlay
                muted
                loop
                playsInline
              >
                <source src={video} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      </div>

      {openJoin && (
        <JoinWaitlist
          onClick={() => setOpenJoin(false)}
          setOpenJoin={setOpenJoin}
          setOpenOffer={setOpenOffer}
        />
      )}
      {openOffer && (
        <Offer
          setOpenOffer={setOpenOffer}
          setOpenSuccess={setOpenSuccess}
          onClick={() => setOpenOffer(false)}
        />
      )}
      {openSuccess && <Success setOpenSuccess={setOpenSuccess} />}
    </>
  );
}
