import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Baner from "./screens/Baner/Baner";
import Features from "./screens/Features/Features";
import Footer from "./screens/Footer/Footer";
import GetApp from "./screens/GetApp/GetApp";
import Header from "./screens/Header/Header";
import News from "./screens/News/News";
import PlayerCards from "./screens/PlayerCards/PlayerCards";
import Video from "./screens/Video/Video";
import Privacy from "./pages/Privacy";
import AnimatedCursor from "react-animated-cursor";
import Terms from "./pages/Terms";
import { useIsMobile } from "./components/useIsMobile";
import Delete from "./pages/Delete";

function App() {
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile && (
        <AnimatedCursor
          innerSize={8}
          outerSize={35}
          innerScale={1}
          outerScale={1.7}
          outerAlpha={0}
          outerStyle={{
            border: "1px solid #8334FF",
          }}
          innerStyle={{
            backgroundColor: "#8334FF",
          }}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            "label[for]",
            "select",
            "textarea",
            "button",
            ".link",
          ]}
        />
      )}

      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <Baner />
              <PlayerCards />
              <News />
              <GetApp />
              <Features />
              {/* <Video /> */}

              <Footer />
            </>
          }
        />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/delete-account" element={<Delete />} />
      </Routes>
    </>
  );
}

export default App;
