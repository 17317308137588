import { useState, useEffect } from "react";

export function useIsTablet() {
  const [isTablet, setIsTablet] = useState(
    window.matchMedia("(min-width: 500px) and (max-width: 1200px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia(
      "(min-width: 500px) and (max-width: 1200px)"
    );
    const handleChange = () => setIsTablet(mediaQuery.matches);

    mediaQuery.addEventListener("change", handleChange);
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  return isTablet;
}
