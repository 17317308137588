import React, { useState, useEffect } from "react";
import "./Offer.scss";
import Close from "../../../assets/images/icons/ic_close.svg";
import { useTranslation } from "react-i18next";
import Input from "../../Input/Input";
import Radius from "../../Button/Radius/Radius";
import MultiSelectDropdown from "../../Dropdown/MultiSelectDropdown";
import FullButton from "../../Button/FullButton/FullButton";
import axios from "axios";
import { useIsMobile } from "../../useIsMobile";
import ModalDeskopt from "../../../assets/images/desktopModal1.svg";
import ModalMobile from "../../../assets/images/mobileModal1.svg";

export default function Offer({
  onClick,
  setOpenOffer,
  setOpenSuccess,
  newsletterId,
}) {
  const { t, i18n } = useTranslation();
  const [activeTitle, setActiveTitle] = useState(null);
  const isMobile = useIsMobile();

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://stage.skillset.tv/api/newsletter/data", {
        headers: {
          Locale: localStorage.getItem("i18nextLng") == "sp" ? "es" : "en",
        },
      })
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [date, setDate] = useState("");
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchPositions, setSearchPositions] = useState("");
  const [searchTeams, setSearchTeams] = useState("");
  const [searchPlayers, setSearchPlayers] = useState("");
  const [errors, setErrors] = useState("");

  const handleClick = (gender) => {
    setActiveTitle(gender);
  };

  useEffect(() => {
    if (searchTeams) {
      axios
        .get(
          `https://stage.skillset.tv/api/newsletter/team/search?q=${searchTeams}`
        )
        .then((response) => {
          setTeams(response.data);
          console.log("Teams data:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching teams:", error);
        });
    }
  }, [searchTeams]);

  useEffect(() => {
    if (searchPlayers) {
      axios
        .get(
          `https://stage.skillset.tv/api/newsletter/player/search?q=${searchPlayers}`
        )
        .then((response) => {
          setPlayers(response.data);
          console.log("Players data:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching players:", error);
        });
    }
  }, [searchPlayers]);

  console.log(selectedPlayers, "selectedPlayers");

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchPositionChange = (e) => {
    setSearchPositions(e.target.value);
  };

  const handleSearchTeamChange = (e) => {
    setSearchTeams(e.target.value);
  };

  const handleSearchPlayerChange = (e) => {
    setSearchPlayers(e.target.value);
  };

  const handleButton = () => {
    const formData = {
      newsletter_id: localStorage.getItem("newsletter"),
      first_name: name,
      last_name: lastName,
      birthDate: date,
      gender: activeTitle,
      positions: selectedPositions,
      teams: selectedTeam,
      players: selectedPlayers,
    };

    axios
      .post("https://stage.skillset.tv/api/newsletter/subscribe", formData, {
        headers: {
          Locale: localStorage.getItem("i18nextLng") === "sp" ? "es" : "en",
        },
      })
      .then((response) => {
        setOpenOffer(false);
        setOpenSuccess(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error.response.data.errors);
        setErrors(error.response.data.errors);
      });
  };

  return (
    <div className="offerOverlay">
      {isMobile ? (
        <img src={ModalMobile} alt={ModalMobile} className="gloss" />
      ) : (
        <img src={ModalDeskopt} alt={ModalDeskopt} className="gloss" />
      )}
      <div className="offerContent">
        <img src={Close} alt="Close" className="closeIcon" onClick={onClick} />
        <h2>{t("offer.title")}</h2>
        <p>{t("offer.description")}</p>
        <div className="formWrapper">
          <Input
            label={t("offerPopup.firstName")}
            placeholder={t("offerPopup.enterFirstName")}
            type={"text"}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.first_name && (
            <p className="errorMessage">{errors.first_name}</p>
          )}
          <Input
            label={t("offerPopup.lastName")}
            placeholder={t("offerPopup.enterLastName")}
            type={"text"}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          {errors.last_name && (
            <p className="errorMessage">{errors.last_name}</p>
          )}
          {isMobile && (
            <Input
              label={t("offerPopup.date")}
              placeholder={t("offerPopup.enterDate")}
              type={"date"}
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          )}

          <div className="genderWrapper">
            {!isMobile && (
              <Input
                label={t("offerPopup.date")}
                placeholder={t("offerPopup.enterDate")}
                type={"date"}
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            )}
            <div className="genderCardWrapp">
              <span>{t("offerPopup.gender")}</span>
              <div className="gender">
                <Radius
                  title={t("offerPopup.male")}
                  isActive={activeTitle === 0}
                  onClick={() => handleClick(0)}
                />
                <Radius
                  title={t("offerPopup.female")}
                  isActive={activeTitle === 1}
                  onClick={() => handleClick(1)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="dropdownFields">
          <div className="dropdownWrapper">
            <label>{t("offerPopup.position")}</label>
            <MultiSelectDropdown
              options={data?.skills || []}
              placeholder={t("offerPopup.selectPosition")}
              onChange={(selected) => setSelectedPositions(selected)}
              width="300px"
              onSecondChange={handleSearchPositionChange}
              value={searchPositions}
            />
          </div>

          <div className="dropdownWrapper">
            <label>{t("offerPopup.team")}</label>
            <MultiSelectDropdown
              options={searchTeams ? teams : data?.teams}
              placeholder={t("offerPopup.selectTeam")}
              onChange={(selected) => setSelectedTeam(selected)}
              width="300px"
              onSecondChange={handleSearchTeamChange}
              value={searchTeams}
            />
          </div>

          <div className="dropdownWrapper">
            <label>{t("offerPopup.skills")}</label>
            <MultiSelectDropdown
              options={searchPlayers ? players : data?.players}
              placeholder={t("offerPopup.players")}
              onChange={(selected) => setSelectedPlayers(selected)}
              onSecondChange={handleSearchPlayerChange}
              width="300px"
              value={searchPlayers}
            />
          </div>
        </div>

        <FullButton text={t("offerPopup.submit")} onClick={handleButton} />
      </div>
    </div>
  );
}
