import React, { useEffect } from "react";
import "./Privacy.scss";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/icons/logo.svg";

export default function Delete() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacyWrapper">
      <a href="/">
        <img className="logo" src={logo} alt={logo} />
      </a>
      <h2>Skillset Account Deletion Guide</h2>
      <p>
        At Skillset, we respect your privacy and provide an easy way to delete
        your account and associated data. Below are the steps to request account
        deletion.
      </p>

      <h4>How to Delete Your Account from the App</h4>

      <p>
        You can delete your account directly from the Skillset mobile app by
        following these steps:
      </p>
      <ul className="order">
        <li>Open the Skillset app on your device.</li>
        <li>Tap on the Menu button.</li>
        <li>Go to the Account section.</li>
        <li>Scroll down to the bottom of the screen.</li>
        <li>Tap on the “Delete Account” button and confirm your request.</li>
      </ul>
      <p>
        Once your account is deleted, your personal data will be{" "}
        <strong>permanently removed within 30 days</strong>.
      </p>

      <h4>Request Account Deletion via Email</h4>
      <p>
        If you are unable to delete your account from the app, you can{" "}
        <strong>request account deletion via email</strong>.
      </p>
      <ul className="order">
        <li>Send an email from your registered email address to:</li>
        <li>
          &#128231;{" "}
          <a href="mailto: support@skillset.tv">support@skillset.tv</a>
        </li>
        <li>
          Use the subject line: <strong>“Delete Account”</strong>
        </li>
        <li>
          In the email, please include:
          <ul style={{ marginTop: "10px" }}>
            <li>
              Your <strong>full name</strong>
            </li>
            <li>
              Your <strong>registered email address</strong>
            </li>
          </ul>
        </li>
      </ul>

      <p>
        Once we receive your request, we will process the deletion within{" "}
        <strong>30 days</strong>.
      </p>

      <h4>Data Retention Policy</h4>
      <ul>
        <li>
          Your account and all associated data will be{" "}
          <strong>permanently deleted within 30 days</strong> of the deletion
          request.
        </li>
        <li>
          During this period, you will not be able to recover your account.
        </li>
        <li>
          Some anonymized data may be retained for legal or analytical purposes.
        </li>
      </ul>

      <p>
        If you have any questions or need further assistance, feel free to
        contact our support team at &#128231;{" "}
        <a href="mailto: support@skillset.tv">support@skillset.tv</a>
      </p>
    </div>
  );
}
