import React, { useState } from "react";
import "./Dropdown.scss";

// Images
import Arrow from "../../assets/images/icons/down_arrow.svg";
import English from "../../assets/images/icons/english_flag.svg";
import Spanish from "../../assets/images/icons/spanish_flag.svg";

// Components
import Flag from "../Flag/Flag";
import { useTranslation } from "react-i18next";

export default function Dropdown() {
  const currentLang = localStorage.getItem("i18nextLng");
  const [openLanguages, setOpenLanguages] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );
  const { t, i18n } = useTranslation();

  const handleChangeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
    localStorage.setItem("i18nextLng", newLanguage);
    window.location.reload();
  };

  const handleEngLand = () => {
    handleChangeLanguage("en");
    setOpenLanguages(false);
  };

  const handleEsLang = () => {
    handleChangeLanguage("sp");
    setOpenLanguages(false);
  };

  return (
    <>
      <div
        className="langSelected"
        onClick={() => setOpenLanguages(!openLanguages)}
      >
        <span>{currentLanguage == "en" ? "EN" : "ES"}</span>{" "}
        <img src={Arrow} alt={Arrow} className={openLanguages && "opened"} />
      </div>

      {openLanguages && (
        <div className="languagesModal">
          <Flag
            source={English}
            flag={t("flag.english")}
            onClick={() => handleEngLand()}
            isActive={currentLanguage === "en"}
          />
          <Flag
            source={Spanish}
            flag={t("flag.spanish")}
            onClick={() => handleEsLang()}
            isActive={currentLanguage === "sp"}
          />
        </div>
      )}
    </>
  );
}
