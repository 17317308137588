import { useTranslation } from "react-i18next";

// Player Logo
import MadridLogo from "../assets/images/madrid_logo.svg";
import BarcelonaLogo from "../assets/images/FC_Barcelona.svg";
import JuventusLogo from "../assets/images/Juventus.svg";
import LiverpoolLogo from "../assets/images/Liverpool.svg";
import LA from "../assets/images/LA.svg";

import Hr from "../assets/images/flags/hr.svg";
import Pl from "../assets/images/flags/pl.svg";
import It from "../assets/images/flags/it.svg";
import Es from "../assets/images/flags/es.svg";
import Nl from "../assets/images/flags/nl.svg";
import Be from "../assets/images/flags/be.svg";
import Ar from "../assets/images/flags/ar.svg";
import Br from "../assets/images/flags/br.svg";

// Player Background
import ModricBackground from "../assets/images/modric_background.jpg";
import LewandowskiBackground from "../assets/images/lewandowsk_background.png";
import ChielliniBackground from "../assets/images/giorgioHorizontal.jpg";
import TorresBackground from "../assets/images/TorresBackground.png";
import VirgilBackgrond from "../assets/images/virgilBackgrond.png";
import AlexiaBackground from "../assets/images/AlexiaBackground.png";
import ThibautBackground from "../assets/images/ThibautBackground.png";
import sergioBackground from "../assets/images/sergioBackground.jpg";
import BufonBackground from "../assets/images/bufon.jpg";
import CarlosBackground from "../assets/images/CarlosBackground.jpg";
import pedriBackground from "../assets/images/pedriBackground.jpg";

// Player Image
import Modric from "../assets/images/Modric.webp";
import Alexia from "../assets/images/AlexiaPlayer.webp";
import BufonPlayer from "../assets/images/BufonPlayer.webp";
import TorresPlayer from "../assets/images/ForresPlayer.webp";
import giorgioPlayer from "../assets/images/giorgioPlayer.webp";
import LewandowskiPlayer from "../assets/images/LewandowskiPlayer.webp";
import PedriPlayer from "../assets/images/PedriPlayer.webp";
import CarlosPlayer from "../assets/images/CarlosPlayer.webp";
import SergioPlayer from "../assets/images/SergioPlayer.webp";
import TiboutPlayer from "../assets/images/TiboutPlayer.webp";
import VirgilPlayer from "../assets/images/VirgilPlayer.webp";

export const usePlayersData = () => {
  const { t } = useTranslation();

  return [
    {
      id: 1,
      name: "Luka",
      surname: "Modric",
      image: Modric,
      logo: Hr,
      background: ModricBackground,

      tags: [
        {
          id: 1,
          name: t("players.course"),
          number: 9,
        },
        {
          id: 2,
          name: t("players.challenge"),
          number: 7,
        },
      ],
      description1: t("players.modricDesciption1"),
      // description2: t("players.modricDesciption2"),
    },
    {
      id: 2,
      name: "Robert",
      surname: "Lewandowski",
      image: LewandowskiPlayer,
      logo: Pl,
      background: LewandowskiBackground,
      tags: [
        {
          id: 1,
          name: t("players.course"),
          number: 7,
        },
        {
          id: 2,
          name: t("players.challenge"),
          number: 12,
        },
      ],
      description1: t("players.lewandowskiDescription1"),
      // description2: t("players.lewandowskiDescription2"),
    },
    {
      id: 3,
      name: "Giorgio",
      surname: "Chiellini",
      image: giorgioPlayer,
      logo: It,
      background: ChielliniBackground,
      tags: [
        {
          id: 1,
          name: t("players.course"),
          number: 9,
        },
        {
          id: 2,
          name: t("players.challenge"),
          number: 9,
        },
      ],
      description1: t("players.giorgioDescription1"),
      // description2: t("players.giorgioDescription2"),
    },
    {
      id: 4,
      name: "Ferran",
      surname: "Torres",
      image: TorresPlayer,
      logo: Es,
      background: TorresBackground,
      tags: [
        {
          id: 1,
          name: t("players.course"),
          number: 5,
        },
        {
          id: 2,
          name: t("players.challenge"),
          number: 7,
        },
      ],
      description1: t("players.torresDescription1"),
      // description2: t("players.torresDescription2"),
    },
    {
      id: 5,
      name: "Virgil",
      surname: "Van Dijk",
      image: VirgilPlayer,
      logo: Nl,
      background: VirgilBackgrond,
      tags: [
        {
          id: 1,
          name: t("players.course"),
          number: 8,
        },
        {
          id: 2,
          name: t("players.challenge"),
          number: 10,
        },
      ],
      description1: t("players.virgilDescription1"),
      // description2: t("players.virgilDescription2"),
    },
    {
      id: 6,
      name: "Alexia",
      surname: "Putellas",
      image: Alexia,
      logo: Es,
      background: AlexiaBackground,
      tags: [
        {
          id: 1,
          name: t("players.course"),
          number: 10,
        },
        {
          id: 2,
          name: t("players.challenge"),
          number: 14,
        },
      ],
      description1: t("players.alexiaDescription1"),
      // description2: t("players.alexiaDescription2"),
    },
    {
      id: 7,
      name: "Thibaut",
      surname: "Courtois",
      image: TiboutPlayer,
      logo: Be,
      background: ThibautBackground,
      tags: [
        {
          id: 1,
          name: t("players.course"),
          number: 5,
        },
        {
          id: 2,
          name: t("players.challenge"),
          number: 7,
        },
      ],
      description1: t("players.thibautDescription1"),
      // description2: t("players.thibautDescription2"),
    },
    {
      id: 8,
      name: "Sergio",
      surname: "Agüero",
      image: SergioPlayer,
      logo: Ar,
      background: sergioBackground,
      tags: [
        {
          id: 1,
          name: t("players.course"),
          number: 7,
        },
        {
          id: 2,
          name: t("players.challenge"),
          number: 9,
        },
      ],
      description1: t("players.sergioDescription1"),
      // description2: t("players.sergioDescription2"),
    },
    {
      id: 9,
      name: "Gianluigi",
      surname: "Bufon",
      image: BufonPlayer,
      logo: It,
      background: BufonBackground,
      tags: [
        {
          id: 1,
          name: t("players.course"),
          number: 5,
        },
        {
          id: 2,
          name: t("players.challenge"),
          number: 7,
        },
      ],
      description1: t("players.buffonDescription1"),
      // description2: t("players.buffonDescription2"),
    },
    {
      id: 10,
      name: "Roberto",
      surname: "Carlos",
      image: CarlosPlayer,
      logo: Br,
      background: CarlosBackground,
      tags: [
        {
          id: 1,
          name: t("players.course"),
          number: 10,
        },
        {
          id: 2,
          name: t("players.challenge"),
          number: 14,
        },
      ],
      description1: t("players.carlosDescription1"),
      // description2: t("players.carlosDescription2"),
    },
    {
      id: 11,
      name: "Pedri",
      surname: "González",
      image: PedriPlayer,
      logo: Es,
      background: pedriBackground,
      tags: [
        {
          id: 1,
          name: t("players.course"),
          number: 9,
        },
        {
          id: 2,
          name: t("players.challenge"),
          number: 12,
        },
      ],
      description1: t("players.pedriDescription1"),
      // description2: t("players.pedriDescription2"),
    },
  ];
};
