import React from "react";
import Tags from "../../Tags/Tags";
import "./HorizontalCard.scss";

export default function HorizontalCard({
  backgroundImage,
  name,
  title,
  description,
  tags,
  tagColor,
  tagBackground,
  tagTitle,
}) {
  return (
    <div
      className="horizontalCardWrapper"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div
        style={{
          backgroundImage:
            "linear-gradient(180deg, rgba(13, 0, 20, 0) 0%, rgba(13, 0, 20, 0.8) 60%, rgba(13, 0, 20, 0.9) 100%, rgba(13, 0, 20, 1) 100%)",
          backdropFilter: "blur(3px)",
        }}
      >
        <div style={{ padding: "10px" }}>
          <h4>{name}</h4>
          <h2>{title}</h2>
          <div className="tags">
            <Tags
              color={tagColor}
              backgroundColor={tagBackground}
              title={tagTitle}
            />
          </div>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}
