import React from "react";
import "./Input.scss";

export default function Input({ label, placeholder, type, value, onChange }) {
  return (
    <div>
      <div className="label">{label}</div>
      <input
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
