import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Text from "../../components/Text/Text";
import "./Features.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styled from "styled-components";
import { Navigation, Pagination } from "swiper/modules";
import { useIsMobile } from "../../components/useIsMobile";
import { useIsTablet } from "../../components/useIsTablet";
import video from "../../assets/video/arrows.webm";

import Discover from "../../assets/images/discover.svg";
import Overview from "../../assets/images/overview.svg";
import Lesson from "../../assets/images/lesson.svg";
import Progress from "../../assets/images/progress.svg";
import { Slide } from "react-awesome-reveal";
import OnScrollTypography from "../../components/Text/OnScrollTypography";
import JoinWaitlist from "../../components/Modal/JoinWaitlist/JoinWaitlist";
import Offer from "../../components/Modal/Offer/Offer";
import Success from "../../components/Modal/Success/Success";

const SliderContainer = styled.div`
  margin-top: 30px;
`;

const SlideWrapp = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  img {
    width: 100%;
  }

  h2 {
    color: #fff;
    font-size: 22px;
    text-align: left;
    line-height: 30px;
    margin: 10px 0 4px;
  }

  p {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
  }

  .swiper-pagination-fraction {
    background: #fff;
    width: fit-content;
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
`;

export default function Features() {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
   const [openJoin, setOpenJoin] = useState(false);
   const [openOffer, setOpenOffer] = useState(false);
   const [openSuccess, setOpenSuccess] = useState(false);

   const handleGetStarted = () => {
     setOpenJoin(true);
   };

  const slides = [
    {
      image: Discover,
      title: `${t("features.title1")}`,
      description: `${t("features.description1")}`,
    },
    {
      image: Overview,
      title: `${t("features.title2")}`,
      description: `${t("features.description2")}`,
    },
    {
      image: Lesson,
      title: `${t("features.title3")}`,
      description: `${t("features.description3")}`,
    },
    {
      image: Progress,
      title: `${t("features.title4")}`,
      description: `${t("features.description4")}`,
    },
  ];

  return (
    <>
      <div className="featuresWrapper" id="aboutApp">
        {isMobile && (
          <video
            style={{
              width: "300px",
              height: "300px",
              position: "absolute",
              transform: "rotate(180deg)",
              left: 0,
              top: "-150px",
              zIndex: "-1",
            }}
            autoPlay
            muted
            loop
            playsInline
          >
            <source src={video} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        )}
        <OnScrollTypography
          smallTitle={t("features.smallTitle")}
          title={t("features.title")}
          description={t("features.description")}
          text={t("features.button")}
          className={"smallButton"}
          desktopStyle={"featureDesktopWrapper"}
          onClick={() => handleGetStarted()}
        />

        {isMobile && !isTablet && (
          <SliderContainer>
            <Swiper
              modules={[Pagination, Navigation]}
              pagination={{
                type: "fraction",
              }}
              navigation
              loop
              slidesPerView={1}
              spaceBetween={20}
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <Slide direction="up" cascade triggerOnce>
                    <SlideWrapp>
                      <img src={slide.image} alt={slide.player} />
                      <h2>{slide.title}</h2>
                      <p>{slide.description}</p>
                    </SlideWrapp>
                  </Slide>
                </SwiperSlide>
              ))}
            </Swiper>
          </SliderContainer>
        )}

        {!isMobile && (
          <div className="featuresCards">
            <Slide direction="up" cascade triggerOnce>
              {slides.map((slide, index) => (
                <div key={index}>
                  <div className="desktopCard">
                    <img src={slide.image} alt={slide.player} />
                    <div className="desktopCardContent">
                      <h2>{slide.title}</h2>
                      <p>{slide.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slide>
          </div>
        )}

        {isTablet && (
          <SliderContainer>
            <Swiper
              modules={[Pagination, Navigation]}
              pagination={{
                type: "fraction",
              }}
              navigation
              loop
              slidesPerView={2}
              spaceBetween={20}
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <Slide direction="up" cascade triggerOnce>
                    <SlideWrapp>
                      <img src={slide.image} alt={slide.player} />
                      <h2>{slide.title}</h2>
                      <p>{slide.description}</p>
                    </SlideWrapp>
                  </Slide>
                </SwiperSlide>
              ))}
            </Swiper>
          </SliderContainer>
        )}
      </div>

      {openJoin && (
        <JoinWaitlist
          onClick={() => setOpenJoin(false)}
          setOpenJoin={setOpenJoin}
          setOpenOffer={setOpenOffer}
        />
      )}
      {openOffer && (
        <Offer
          setOpenOffer={setOpenOffer}
          setOpenSuccess={setOpenSuccess}
          onClick={() => setOpenOffer(false)}
        />
      )}
      {openSuccess && <Success setOpenSuccess={setOpenSuccess} />}
    </>
  );
}
