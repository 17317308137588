import React, { useState } from "react";
import "./JoinWaitlist.scss";
import Close from "../../../assets/images/icons/ic_close.svg";
import FullButton from "../../Button/FullButton/FullButton";
import Input from "../../Input/Input";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ModalDeskopt from "../../../assets/images/desktopModal1.svg";
import ModalMobile from "../../../assets/images/mobileModal1.svg";
import { useIsMobile } from "../../useIsMobile";

export default function JoinWaitlist({ onClick, setOpenJoin, setOpenOffer }) {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("")
  const isMobile = useIsMobile();

  const handleButton = () => {
    axios
      .post("https://stage.skillset.tv/api/newsletter/email/verify", {
        email: email,
      })
      .then((response) => {
        setOpenJoin(false);
        setOpenOffer(true);
        localStorage.setItem("newsletter", response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error.response.data.message);
        setError(error.response.data.errors.email);
      });
  };

  return (
    <>
      <div className="joinWaitlistOverlay">
        {isMobile ? (
          <img src={ModalMobile} alt={ModalMobile} className="gloss" />
        ) : (
          <img src={ModalDeskopt} alt={ModalDeskopt} className="gloss" />
        )}
        <div className="joinWaitlistContent">
          <img
            src={Close}
            alt="Close"
            className="closeIcon"
            onClick={onClick}
          />
          <h2>{t("joinWaitlist.title")}</h2>
          <p>{t("joinWaitlist.description")}</p>

          <div className="formWrapper">
            <Input
              label={t("joinWaitlist.email")}
              placeholder={t("joinWaitlist.placeholder")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {error && <p className="errorMessage">{error}</p>}
          </div>
          {email == "" ? (
            <FullButton
              text={t("joinWaitlist.button")}
              onClick={handleButton}
              className={"disabledButton"}
              disabled
            />
          ) : (
            <FullButton
              text={t("joinWaitlist.button")}
              onClick={handleButton}
            />
          )}
        </div>
      </div>
    </>
  );
}
