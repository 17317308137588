import React, { useEffect } from "react";
import "./Privacy.scss";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/icons/logo.svg";

export default function Privacy() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacyWrapper">
      <a href="/">
        <img className="logo" src={logo} alt={logo} />
      </a>
      <h1>Privacy policy</h1>
      <h3>Last updated May 11, 2023</h3>
      <p>
        This privacy notice for Skillset Media Ltd (“Company,” “we,” “us,” or
        “our“), describes how and why we might collect, store, use, and/or share
        (“process“) your information when you use our services (“Services“),
        such as when you:
      </p>
      <ul>
        <li>
          Visit our website at http://www.skillset.tv, or any website of ours
          that links to this privacy notice
        </li>
        <li>
          Download and use our mobile application (Skillset), or any other
          application of ours that links to this privacy notice
        </li>
        <li>
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>
      <h4>Questions or concerns?</h4>
      <p>
        Reading this privacy notice will help you understand your privacy rights
        and choices. If you do not agree with our policies and practices, please
        do not use our Services. If you still have any questions or concerns,
        please contact us at info@skillset.tv.
      </p>
      <h2>Summary of key points</h2>
      <p>
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics by clicking the link
        following each key point or by using our <a>table of contents</a> below
        to find the section you are looking for.
      </p>
      <h4>What personal information do we process?</h4>
      <p>
        When you visit, use, or navigate our Services, we may process personal
        information depending on how you interact with Skillset Media Ltd and
        the Services, the choices you make, and the products and features you
        use. Learn more about personal information you disclose to us.
      </p>
      <h4>Do we process any sensitive personal information?</h4>
      <p>We do not process sensitive personal information.</p>
      <h4>Do we receive any information from third parties?</h4>
      <p>We do not receive any information from third parties.</p>
      <h4>How do we process your information?</h4>
      <p>
        We process your information to provide, improve, and administer our
        Services, communicate with you, for security and fraud prevention, and
        to comply with law. We may also process your information for other
        purposes with your consent. We process your information only when we
        have a valid legal reason to do so. Learn more about{" "}
        <a>how we process your information.</a>
      </p>
      <h4>
        In what situations and with which parties do we share personal
        information?
      </h4>
      <p>
        We may share information in specific situations and with specific third
        parties. Learn more about{" "}
        <a>when and with whom we share your personal information.</a>
      </p>
      <h4>How do we keep your information safe?</h4>
      <p>
        We have organizational and technical processes and procedures in place
        to protect your personal information. However, no electronic
        transmission over the internet or information storage technology can be
        guaranteed to be 100% secure, so we cannot promise or guarantee that
        hackers, cybercriminals, or other unauthorized third parties will not be
        able to defeat our security and improperly collect, access, steal, or
        modify your information. Learn more about how we keep your information
        safe.
      </p>
      <h4> What are your rights?</h4>
      <p>
        Depending on where you are located geographically, the applicable
        privacy law may mean you have certain rights regarding your personal
        information. Learn more about <a>your privacy rights</a>.
      </p>
      <h4> How do you exercise your rights?</h4>
      <p>
        The easiest way to exercise your rights is by visiting info@skillset.tv,
        or by contacting us. We will consider and act upon any request in
        accordance with applicable data protection laws.
      </p>
      <h4>
        {" "}
        Want to learn more about what Skillset Media Ltd does with any
        information we collect?
      </h4>
      <a>Review the privacy notice in full.</a>
      <h2>Table of contact</h2>
      <ul className="order">
        <li>
          <a href="/privacy/#info-collect">What information do we collect?</a>
        </li>
        <li>
          <a href="/privacy/#info-process">
            How do we process your information?
          </a>
        </li>
        <li>
          <a href="/privacy/#legal-bases">
            What legal bases do we rely on to process your information?
          </a>
        </li>
        <li>
          <a href="/privacy/#personal-info">
            When and with whom do we share your personal information?
          </a>
        </li>
        <li>
          <a href="/privacy/#our-stance">
            What is our stance on third-party websites?
          </a>
        </li>
        <li>
          <a href="/privacy/#handle-socila-login">
            How do we handle your social logins?
          </a>
        </li>
        <li>
          <a href="/privacy/#info_keep">
            How long do we keep your information?
          </a>
        </li>
        <li>
          <a href="/privacy/#how-we-keep-info-safe">
            How do we keep your information safe?
          </a>
        </li>
        <li>
          <a href="/privacy/#privacy-rights">What are your privacy rights?</a>
        </li>
        <li>
          <a href="/privacy/#controls-no-track">
            Controls for do-not-track features
          </a>
        </li>
        <li>
          <a href="/privacy/#specific-privacy-rights">
            Do california residents have specific privacy rights?
          </a>
        </li>
        <li>
          <a href="/privacy/#virginia-privacy">
            Do virginia residents have specific privacy rights?
          </a>
        </li>
        <li>
          <a href="/privacy/#updates">Do we make updates to this notice?</a>
        </li>
        <li>
          <a href="/privacy/#how-to-contact-us">
            How can you contact us about this notice?
          </a>
        </li>
        <li>
          <a href="/privacy/#review-update-delete">
            How can you review, update, or delete the data we collect from you?
          </a>
        </li>
      </ul>
      <h2 id="info-collect">1. What information do we collect?</h2>
      <p>
        <strong>Personal information you disclose to us</strong>
      </p>
      <br />
      <p>
        <strong>In Short:</strong> We collect personal information that you
        provide to us.
      </p>
      <br />
      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </p>
      <h4> Personal Information Provided by You.</h4>
      <p>
        The personal information that we collect depends on the context of your
        interactions with us and the Services, the choices you make, and the
        products and features you use. The personal information we collect may
        include the following:
      </p>
      <ul>
        <li>Names</li>
        <li>Phone numbers</li>
        <li>Email addresses</li>
        <li>Usernames</li>
        <li>Passwords</li>
        <li>Billing addresses</li>
        <li>Contact or authentication data</li>
        <li>Debit/credit card numbers</li>
        <li>Leg preference</li>
        <li>Favorite teams</li>
      </ul>
      <h4>Payment Data.</h4>
      <p>
        We may collect data necessary to process your payment if you make
        purchases, such as your payment instrument number, and the security code
        associated with your payment instrument. All payment data is stored by
        Google Pay and Apple Pay. You may find their privacy notice link(s)
        here:{" "}
        <a href="https://policies.google.com/privacy" target="_blank">
          https://policies.google.com/privacy
        </a>{" "}
        and{" "}
        <a
          href="https://www.apple.com/legal/privacy/data/en/apple-pay/"
          target="_blank"
        >
          https://www.apple.com/legal/privacy/data/en/apple-pay/
        </a>
        .
      </p>
      <h4>Social Media Login Data.</h4>
      <p>
        We may provide you with the option to register with us using your
        existing social media account details, like your Facebook, Twitter, or
        other social media account. If you choose to register in this way, we
        will collect the information described in the section called{" "}
        <a
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba3f6bf9-d817-45d5-8f45-a381957f37c8#sociallogins"
          target="_blank"
        >
          “HOW DO WE HANDLE YOUR SOCIAL LOGINS?”
        </a>{" "}
        below.
      </p>
      <h4>Application Data.</h4>
      <p>
        If you use our application(s), we also may collect the following
        information if you choose to provide us with access or permission:
      </p>
      <ul>
        <li>
          Mobile Device Access. We may request access or permission to certain
          features from your mobile device, including your mobile device’s
          camera, sensors, storage, reminders, social media accounts, and other
          features. If you wish to change our access or permissions, you may do
          so in your device’s settings.
        </li>
        <li>
          Mobile Device Data. We automatically collect device information (such
          as your mobile device ID, model, and manufacturer), operating system,
          version information and system configuration information, device and
          application identification numbers, browser type and version, hardware
          model Internet service provider and/or mobile carrier, and Internet
          Protocol (IP) address (or proxy server). If you are using our
          application(s), we may also collect information about the phone
          network associated with your mobile device, your mobile device’s
          operating system or platform, the type of mobile device you use, your
          mobile device’s unique device ID, and information about the features
          of our application(s) you accessed.
        </li>
        <li>
          Push Notifications. We may request to send you push notifications
          regarding your account or certain features of the application(s). If
          you wish to opt out from receiving these types of communications, you
          may turn them off in your device’s settings.
        </li>
      </ul>
      <p>
        This information is primarily needed to maintain the security and
        operation of our application(s), for troubleshooting, and for our
        internal analytics and reporting purposes.
      </p>
      <br />
      <p>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>
      <h4>Information automatically collected</h4>
      <p>
        <strong>In Short:</strong> Some information — such as your Internet
        Protocol (IP) address and/or browser and device characteristics — is
        collected automatically when you visit our Services.
      </p>
      <br />
      <p>
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.
      </p>
      <br />
      <p>The information we collect includes:</p>
      <ul>
        <li>
          Log and Usage Data. Log and usage data is service-related, diagnostic,
          usage, and performance information our servers automatically collect
          when you access or use our Services and which we record in log files.
          Depending on how you interact with us, this log data may include your
          IP address, device information, browser type, and settings and
          information about your activity in the Services (such as the date/time
          stamps associated with your usage, pages and files viewed, searches,
          and other actions you take such as which features you use), device
          event information (such as system activity, error reports (sometimes
          called “crash dumps”), and hardware settings).
        </li>
        <li>
          Device Data. We collect device data such as information about your
          computer, phone, tablet, or other device you use to access the
          Services. Depending on the device used, this device data may include
          information such as your IP address (or proxy server), device and
          application identification numbers, location, browser type, hardware
          model, Internet service provider and/or mobile carrier, operating
          system, and system configuration information.
        </li>
        <li>
          Location Data. We collect location data such as information about your
          device’s location, which can be either precise or imprecise. How much
          information we collect depends on the type and settings of the device
          you use to access the Services. For example, we may use GPS and other
          technologies to collect geolocation data that tells us your current
          location (based on your IP address). You can opt out of allowing us to
          collect this information either by refusing access to the information
          or by disabling your Location setting on your device. However, if you
          choose to opt out, you may not be able to use certain aspects of the
          Services.
        </li>
      </ul>
      <h2 id="info-process">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
      <p>
        <strong> In Short:</strong> We process your information to provide,
        improve, and administer our Services, communicate with you, for security
        and fraud prevention, and to comply with law. We may also process your
        information for other purposes with your consent.
      </p>
      <p>
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
      </p>
      <ul>
        <li>
          To facilitate account creation and authentication and otherwise manage
          user accounts. We may process your information so you can create and
          log in to your account, as well as keep your account in working order.
        </li>
        <li>
          To deliver and facilitate delivery of services to the user. We may
          process your information to provide you with the requested service.
        </li>
        <li>
          To respond to user inquiries/offer support to users. We may process
          your information to respond to your inquiries and solve any potential
          issues you might have with the requested service.
        </li>
        <li>
          To send administrative information to you. We may process your
          information to send you details about our products and services,
          changes to our terms and policies, and other similar information.
        </li>
        <li>
          To enable user-to-user communications. We may process your information
          if you choose to use any of our offerings that allow for communication
          with another user.
        </li>
        <li>
          To request feedback. We may process your information when necessary to
          request feedback and to contact you about your use of our Services.
        </li>
        <li>
          To send you marketing and promotional communications. We may process
          the personal information you send to us for our marketing purposes, if
          this is in accordance with your marketing preferences. You can opt out
          of our marketing emails at any time. For more information, see{" "}
          <a
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba3f6bf9-d817-45d5-8f45-a381957f37c8#privacyrights"
            target="_blank"
          >
            “WHAT ARE YOUR PRIVACY RIGHTS?”
          </a>{" "}
          below.
        </li>
        <li>
          To deliver targeted advertising to you. We may process your
          information to develop and display personalized content and
          advertising tailored to your interests, location, and more.
        </li>
        <li>
          To protect our Services. We may process your information as part of
          our efforts to keep our Services safe and secure, including fraud
          monitoring and prevention.
        </li>
        <li>
          To identify usage trends. We may process information about how you use
          our Services to better understand how they are being used so we can
          improve them.
        </li>
        <li>
          To determine the effectiveness of our marketing and promotional
          campaigns. We may process your information to better understand how to
          provide marketing and promotional campaigns that are most relevant to
          you.
        </li>
        <li>
          To save or protect an individual’s vital interest. We may process your
          information when necessary to save or protect an individual’s vital
          interest, such as to prevent harm.
        </li>
      </ul>
      <h2 id="legal-bases">
        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
      </h2>
      <p>
        <strong>In Short:</strong> We only process your personal information
        when we believe it is necessary and we have a valid legal reason (i.e.,
        legal basis) to do so under applicable law, like with your consent, to
        comply with laws, to provide you with services to enter into or fulfill
        our contractual obligations, to protect your rights, or to fulfill our
        legitimate business interests.
      </p>
      <h4>If you are located in the EU or UK, this section applies to you.</h4>
      <p>
        The General Data Protection Regulation (GDPR) and UK GDPR require us to
        explain the valid legal bases we rely on in order to process your
        personal information.
      </p>
      <br />
      <p>
        As such, we may rely on the following legal bases to process your
        personal information:
      </p>
      <ul>
        <li>
          Consent. We may process your information if you have given us
          permission (i.e., consent) to use your personal information for a
          specific purpose. You can withdraw your consent at any time. Learn
          more about{" "}
          <a
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba3f6bf9-d817-45d5-8f45-a381957f37c8#withdrawconsent"
            target="_blank"
          >
            withdrawing your consent.
          </a>
        </li>
        <li>
          Performance of a Contract. We may process your personal information
          when we believe it is necessary to fulfill our contractual obligations
          to you, including providing our Services or at your request prior to
          entering into a contract with you.
        </li>
        <li>
          Legitimate Interests. We may process your information when we believe
          it is reasonably necessary to achieve our legitimate business
          interests and those interests do not outweigh your interests and
          fundamental rights and freedoms. For example, we may process your
          personal information for some of the purposes described in order to:
        </li>
        <li>
          Send users information about special offers and discounts on our
          products and services
        </li>
        <li>
          Develop and display personalized and relevant advertising content for
          our users
        </li>
        <li>
          Analyze how our Services are used so we can improve them to engage and
          retain users
        </li>
        <li>Support our marketing activities</li>
        <li>Diagnose problems and/or prevent fraudulent activities</li>
        <li>
          Understand how our users use our products and services so we can
          improve user experience
        </li>
        <li>
          Legal Obligations. We may process your information where we believe it
          is necessary for compliance with our legal obligations, such as to
          cooperate with a law enforcement body or regulatory agency, exercise
          or defend our legal rights, or disclose your information as evidence
          in litigation in which we are involved.
        </li>
        <li>
          Vital Interests. We may process your information where we believe it
          is necessary to protect your vital interests or the vital interests of
          a third party, such as situations involving potential threats to the
          safety of any person.
        </li>
      </ul>
      <h4>If you are located in Canada, this section applies to you.</h4>
      <p>
        We may process your information if you have given us specific permission
        (i.e., express consent) to use your personal information for a specific
        purpose, or in situations where your permission can be inferred (i.e.,
        implied consent). You can{" "}
        <a
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba3f6bf9-d817-45d5-8f45-a381957f37c8#withdrawconsent"
          target="_blank"
        >
          withdraw your consent
        </a>{" "}
        at any time.
      </p>
      <br />
      <p>
        In some exceptional cases, we may be legally permitted under applicable
        law to process your information without your consent, including, for
        example:
      </p>
      <ul>
        <li>
          If collection is clearly in the interests of an individual and consent
          cannot be obtained in a timely way
        </li>
        <li>For investigations and fraud detection and prevention</li>
        <li>For business transactions provided certain conditions are met</li>
        <li>
          If it is contained in a witness statement and the collection is
          necessary to assess, process, or settle an insurance claim
        </li>
        <li>
          For identifying injured, ill, or deceased persons and communicating
          with next of kin
        </li>
        <li>
          If we have reasonable grounds to believe an individual has been, is,
          or may be victim of financial abuse
        </li>
        <li>
          If it is reasonable to expect collection and use with consent would
          compromise the availability or the accuracy of the information and the
          collection is reasonable for purposes related to investigating a
          breach of an agreement or a contravention of the laws of Canada or a
          province
        </li>
        <li>
          If disclosure is required to comply with a subpoena, warrant, court
          order, or rules of the court relating to the production of records
        </li>
        <li>
          If it was produced by an individual in the course of their employment,
          business, or profession and the collection is consistent with the
          purposes for which the information was produced
        </li>
        <li>
          If the collection is solely for journalistic, artistic, or literary
          purposes
        </li>
        <li>
          If the information is publicly available and is specified by the
          regulations
        </li>
      </ul>
      <h2 id="personal-info">
        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </h2>
      <p>
        <strong>In Short:</strong> We may share information in specific
        situations described in this section and/or with the following third
        parties.
      </p>
      <br />
      <p>
        We may need to share your personal information in the following
        situations:
      </p>
      <ul>
        <li>
          Business Transfers. We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </li>
        <li>
          Business Partners. We may share your information with our business
          partners to offer you certain products, services, or promotions.
        </li>
        <li>
          Offer Wall. Our application(s) may display a third-party hosted “offer
          wall.” Such an offer wall allows third-party advertisers to offer
          virtual currency, gifts, or other items to users in return for the
          acceptance and completion of an advertisement offer. Such an offer
          wall may appear in our application(s) and be displayed to you based on
          certain data, such as your geographic area or demographic information.
          When you click on an offer wall, you will be brought to an external
          website belonging to other persons and will leave our application(s).
          A unique identifier, such as your user ID, will be shared with the
          offer wall provider in order to prevent fraud and properly credit your
          account with the relevant reward.
        </li>
      </ul>
      <h2 id="our-stance">5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h2>
      <p>
        <strong>In Short:</strong> We are not responsible for the safety of any
        information that you share with third parties that we may link to or who
        advertise on our Services, but are not affiliated with, our Services.
      </p>
      <br />
      <p>
        The Services, including our offer wall, may link to third-party
        websites, online services, or mobile applications and/or contain
        advertisements from third parties that are not affiliated with us and
        which may link to other websites, services, or applications.
        Accordingly, we do not make any guarantee regarding any such third
        parties, and we will not be liable for any loss or damage caused by the
        use of such third-party websites, services, or applications. The
        inclusion of a link towards a third-party website, service, or
        application does not imply an endorsement by us. We cannot guarantee the
        safety and privacy of data you provide to any third parties. Any data
        collected by third parties is not covered by this privacy notice. We are
        not responsible for the content or privacy and security practices and
        policies of any third parties, including other websites, services, or
        applications that may be linked to or from the Services. You should
        review the policies of such third parties and contact them directly to
        respond to your questions.
      </p>
      <h2 id="handle-socila-login">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
      <p>
        <strong>In Short:</strong> If you choose to register or log in to our
        Services using a social media account, we may have access to certain
        information about you.
      </p>
      <br />
      <p>
        Our Services offer you the ability to register and log in using your
        third-party social media account details (like your Facebook or Twitter
        logins). Where you choose to do this, we will receive certain profile
        information about you from your social media provider. The profile
        information we receive may vary depending on the social media provider
        concerned, but will often include your name, email address, friends
        list, and profile picture, as well as other information you choose to
        make public on such a social media platform.
      </p>
      <br />
      <p>
        We will use the information we receive only for the purposes that are
        described in this privacy notice or that are otherwise made clear to you
        on the relevant Services. Please note that we do not control, and are
        not responsible for, other uses of your personal information by your
        third-party social media provider. We recommend that you review their
        privacy notice to understand how they collect, use, and share your
        personal information, and how you can set your privacy preferences on
        their sites and apps.
      </p>
      <h2 id="info_keep">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
      <p>
        <strong>In Short:</strong> We keep your information for as long as
        necessary to fulfill the purposes outlined in this privacy notice unless
        otherwise required by law.
      </p>
      <br />
      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than three (3)
        months past the termination of the user’s account.
      </p>
      <br />
      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>
      <h2 id="how-we-keep-info-safe">
        8. HOW DO WE KEEP YOUR INFORMATION SAFE?
      </h2>
      <p>
        <strong>In Short:</strong> We aim to protect your personal information
        through a system of organizational and technical security measures.
      </p>
      <br />
      <p>
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </p>
      <h2 id="privacy-rights">9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
      <p>
        <strong>In Short:</strong> In some regions, such as the European
        Economic Area (EEA), United Kingdom (UK), and Canada, you have rights
        that allow you greater access to and control over your personal
        information. You may review, change, or terminate your account at any
        time.
      </p>
      <br />
      <p>
        In some regions (like the EEA, UK, and Canada), you have certain rights
        under applicable data protection laws. These may include the right (i)
        to request access and obtain a copy of your personal information, (ii)
        to request rectification or erasure; (iii) to restrict the processing of
        your personal information; and (iv) if applicable, to data portability.
        In certain circumstances, you may also have the right to object to the
        processing of your personal information. You can make such a request by
        contacting us by using the contact details provided in the section{" "}
        <a
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba3f6bf9-d817-45d5-8f45-a381957f37c8#contact"
          target="_blank"
        >
          “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?”
        </a>{" "}
        below.
      </p>
      <br />
      <p>
        We will consider and act upon any request in accordance with applicable
        data protection laws.
      </p>
      <br />
      <p>
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your{" "}
        <a
          href="https://ec.europa.eu/newsroom/article29/items/612080"
          target="_blank"
        >
          Member State data protection authority
        </a>{" "}
        or{" "}
        <a
          href="https://ico.org.uk/make-a-complaint/data-protection-complaints/"
          target="_blank"
        >
          UK data protection authority.
        </a>
      </p>
      <br />
      <p>
        If you are located in Switzerland, you may contact the{" "}
        <a href="https://www.edoeb.admin.ch/en" target="_blank">
          Federal Data Protection and Information Commissioner
        </a>
        .
      </p>
      <h4>Withdrawing your consent:</h4>
      <p>
        {" "}
        If we are relying on your consent to process your personal information,
        which may be express and/or implied consent depending on the applicable
        law, you have the right to withdraw your consent at any time. You can
        withdraw your consent at any time by contacting us by using the contact
        details provided in the section{" "}
        <a
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba3f6bf9-d817-45d5-8f45-a381957f37c8#contact"
          target="_blank"
        >
          “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?”
        </a>{" "}
        below or updating your preferences.
      </p>
      <br />
      <p>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </p>
      <h4>Opting out of marketing and promotional communications:</h4>
      <p>
        You can unsubscribe from our marketing and promotional communications at
        any time by clicking on the unsubscribe link in the emails that we send,
        or by contacting us using the details provided in the section{" "}
        <a
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba3f6bf9-d817-45d5-8f45-a381957f37c8#contact"
          target="_blank"
        >
          “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?”
        </a>{" "}
        below. You will then be removed from the marketing lists. However, we
        may still communicate with you — for example, to send you
        service-related messages that are necessary for the administration and
        use of your account, to respond to service requests, or for other
        non-marketing purposes.
      </p>
      <br />
      <p>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </p>
      <h4>Opting out of marketing and promotional communications:</h4>
      <p>
        You can unsubscribe from our marketing and promotional communications at
        any time by clicking on the unsubscribe link in the emails that we send,
        or by contacting us using the details provided in the section{" "}
        <a
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba3f6bf9-d817-45d5-8f45-a381957f37c8#contact"
          target="_blank"
        >
          “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?”
        </a>{" "}
        below. You will then be removed from the marketing lists. However, we
        may still communicate with you — for example, to send you
        service-related messages that are necessary for the administration and
        use of your account, to respond to service requests, or for other
        non-marketing purposes.
      </p>
      <h4>Account Information</h4>
      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>
      <ul>
        <li>Contact us using the contact information provided.</li>
        <li>Log in to your account settings and update your user account.</li>
      </ul>
      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.
      </p>
      <br />
      <p>
        If you have questions or comments about your privacy rights, you may
        email us at info@skillset.tv.
      </p>
      <h2 id="controls-no-track">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track (“DNT”) feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>
      <h2 id="specific-privacy-rights">
        11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </h2>
      <p>
        <strong>In Short:</strong> Yes, if you are a resident of California, you
        are granted specific rights regarding access to your personal
        information.
      </p>
      <br />
      <p>
        California Civil Code Section 1798.83, also known as the “Shine The
        Light” law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <br />
      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with Services, you have the right to request removal
        of unwanted data that you publicly post on the Services. To request
        removal of such data, please contact us using the contact information
        provided below and include the email address associated with your
        account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware
        that the data may not be completely or comprehensively removed from all
        our systems (e.g., backups, etc.).
      </p>
      <h4>CCPA Privacy Notice</h4>
      <p>The California Code of Regulations defines a “resident” as:</p>
      <br />
      <p>
        (1) every individual who is in the State of California for other than a
        temporary or transitory purpose and
      </p>
      <p>
        (2) every individual who is domiciled in the State of California who is
        outside the State of California for a temporary or transitory purpose
      </p>
      <br />
      <p>All other individuals are defined as “non-residents.”</p>
      <br />
      <p>
        If this definition of “resident” applies to you, we must adhere to
        certain rights and obligations regarding your personal information.
      </p>
      <br />
      <h4>What categories of personal information do we collect?</h4>
      <p>
        {" "}
        We have collected the following categories of personal information in
        the past twelve (12) months:
      </p>
      <br />
      <table>
        <thead>
          <tr>
            <th>Category</th>
            <th>Examples</th>
            <th>Collected</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>A. Identifiers</td>
            <td>
              Contact details, such as real name, alias, postal address,
              telephone or mobile contact number, unique personal identifier,
              online identifier, Internet Protocol address, email address, and
              account name
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>
              B. Personal information categories listed in the California
              Customer Records statute
            </td>
            <td>
              Name, contact information, education, employment, employment
              history, and financial information
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>
              C. Protected classification characteristics under California or
              federal law
            </td>
            <td>Gender and date of birth</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>D. Commercial information</td>
            <td>
              Transaction information, purchase history, financial details, and
              payment information
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>E. Biometric information</td>
            <td>Fingerprints and voiceprints</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>F. Internet or other similar network activity</td>
            <td>
              Browsing history, search history, online behavior, interest data,
              and interactions with our and other websites, applications,
              systems, and advertisements
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>G. Geolocation data</td>
            <td>Device location</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>
              H. Audio, electronic, visual, thermal, olfactory, or similar
              information
            </td>
            <td>
              Images and audio, video or call recordings created in connection
              with our business activities
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>I. Professional or employment-related information</td>
            <td>
              Business contact details in order to provide you our Services at a
              business level or job title, work history, and professional
              qualifications if you apply for a job with us
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>J. Education Information</td>
            <td>Student records and directory information</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>K. Inferences drawn from other personal information</td>
            <td>
              Inferences drawn from any of the collected personal information
              listed above to create a profile or summary about, for example, an
              individual’s preferences and characteristics
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>L. Sensitive Personal Information</td>
            <td></td>
            <td>NO</td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        We will use and retain the collected personal information as needed to
        provide the Services or for:
      </p>
      <ul>
        <li>Category K – As long as the user has an account with us</li>
      </ul>
      <p>
        We may also collect other personal information outside of these
        categories through instances where you interact with us in person,
        online, or by phone or mail in the context of:
      </p>
      <ul>
        <li>Receiving help through our customer support channels;</li>
        <li>Participation in customer surveys or contests; and</li>
        <li>
          Facilitation in the delivery of our Services and to respond to your
          inquiries.
        </li>
      </ul>
      <h4>How do we use and share your personal information?</h4>
      <p>
        More information about our data collection and sharing practices can be
        found in this privacy notice. You may contact us by email at
        info@skillset.tv, or by referring to the contact details at the bottom
        of this document.
      </p>
      <br />
      <p>
        If you are using an authorized agent to exercise your right to opt out
        we may deny a request if the authorized agent does not submit proof that
        they have been validly authorized to act on your behalf.
      </p>
      <h4>Will your information be shared with anyone else?</h4>
      <p>
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for-profit entity that processes the
        information on our behalf, following the same strict privacy protection
        obligations mandated by the CCPA.
      </p>
      <br />
      <p>
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be “selling” of your personal
        information.
      </p>
      <br />
      <p>
        Skillset Media Ltd has not disclosed, sold, or shared any personal
        information to third parties for a business or commercial purpose in the
        preceding twelve (12) months. Skillset Media Ltd will not sell or share
        personal information in the future belonging to website visitors, users,
        and other consumers.
      </p>
      <h4>Your rights with respect to your personal data</h4>
      <p>Right to request deletion of the data — Request to delete</p>
      <p>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to
        protect against illegal activities.
      </p>
      <p>Right to be informed — Request to know</p>
      <br />
      <p>Depending on the circumstances, you have a right to know:</p>
      <ul>
        <li>whether we collect and use your personal information;</li>
        <li>the categories of personal information that we collect;</li>
        <li>
          the purposes for which the collected personal information is used;
        </li>
        <li>whether we sell or share personal information to third parties;</li>
        <li>
          the categories of personal information that we sold, shared, or
          disclosed for a business purpose;
        </li>
        <li>
          the categories of third parties to whom the personal information was
          sold, shared, or disclosed for a business purpose;
        </li>
        <li>
          the business or commercial purpose for collecting, selling, or sharing
          personal information;
        </li>
        <li>
          the specific pieces of personal information we collected about you.
        </li>
      </ul>
      <p>
        In accordance with applicable law, we are not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </p>
      <br />
      <p>
        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
        Rights
      </p>
      <br />
      <p>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>
      <br />
      <p>Right to Limit Use and Disclosure of Sensitive Personal Information</p>
      <br />
      <p>We do not process consumer’s sensitive personal information.</p>

      <h4>Verification process</h4>
      <p>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g., phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <br />
      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>

      <h4>Other privacy rights</h4>
      <ul>
        <li>You may object to the processing of your personal information.</li>
        <li>
          You may request correction of your personal data if it is incorrect or
          no longer relevant, or ask to restrict the processing of the
          information.
        </li>
        <li>
          You can designate an authorized agent to make a request under the CCPA
          on your behalf. We may deny a request from an authorized agent that
          does not submit proof that they have been validly authorized to act on
          your behalf in accordance with the CCPA.
        </li>
        <li>
          You may request to opt out from future selling or sharing of your
          personal information to third parties. Upon receiving an opt-out
          request, we will act upon the request as soon as feasibly possible,
          but no later than fifteen (15) days from the date of the request
          submission.
        </li>
      </ul>
      <p>
        To exercise these rights, you can contact us by email at
        info@skillset.tv, or by referring to the contact details at the bottom
        of this document. If you have a complaint about how we handle your data,
        we would like to hear from you.
      </p>

      <h2 id="virginia-privacy">
        12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </h2>
      <p>
        <strong> In Short:</strong> Yes, if you are a resident of Virginia, you
        may be granted specific rights regarding access to and use of your
        personal information.
      </p>

      <h4>Virginia CDPA Privacy Notice</h4>
      <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
      <br />
      <p>
        “Consumer” means a natural person who is a resident of the Commonwealth
        acting only in an individual or household context. It does not include a
        natural person acting in a commercial or employment context.
      </p>
      <br />
      <p>
        “Personal data” means any information that is linked or reasonably
        linkable to an identified or identifiable natural person. “Personal
        data” does not include de-identified data or publicly available
        information.
      </p>
      <br />
      <p>
        “Sale of personal data” means the exchange of personal data for monetary
        consideration.
      </p>
      <br />
      <p>
        If this definition “consumer” applies to you, we must adhere to certain
        rights and obligations regarding your personal data.
      </p>
      <br />
      <p>
        The information we collect, use, and disclose about you will vary
        depending on how you interact with Skillset Media Ltd and our Services.
        To find out more, please visit the following links:
      </p>
      <ul>
        <li>
          <a
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba3f6bf9-d817-45d5-8f45-a381957f37c8#infocollect"
            target="_blank"
          >
            Personal data we collect
          </a>
        </li>
        <li>
          <a
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba3f6bf9-d817-45d5-8f45-a381957f37c8#infouse"
            target="_blank"
          >
            How we use your personal data
          </a>
        </li>
        <li>
          <a
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba3f6bf9-d817-45d5-8f45-a381957f37c8#whoshare"
            target="_blank"
          >
            When and with whom we share your personal data
          </a>
        </li>
      </ul>

      <h4>Your rights with respect to your personal data</h4>
      <ul>
        <li>
          Right to be informed whether or not we are processing your personal
          data
        </li>
        <li>Right to access your personal data</li>
        <li>Right to correct inaccuracies in your personal data</li>
        <li>Right to request deletion of your personal data</li>
        <li>
          Right to obtain a copy of the personal data you previously shared with
          us
        </li>
        <li>
          Right to opt out of the processing of your personal data if it is used
          for targeted advertising, the sale of personal data, or profiling in
          furtherance of decisions that produce legal or similarly significant
          effects (“profiling”)
        </li>
      </ul>
      <p>
        Skillset Media Ltd sells personal data to third parties or processes
        personal data for targeted advertising. Please see the following section
        to find out how you can opt out from further selling or sharing of your
        personal data for targeted advertising or profiling purposes.
      </p>

      <h4>Exercise your rights provided under the Virginia CDPA</h4>
      <br />
      <p>
        More information about our data collection and sharing practices can be
        found in this privacy notice.
      </p>
      <br />
      <p>
        You can opt out from the selling of your personal data, targeted
        advertising, or profiling by disabling cookies in Cookie Preference
        Settings. You may contact us by email at info@skillset.tv, by visiting
        info@skillset.tv, or by referring to the contact details at the bottom
        of this document.
      </p>
      <br />
      <p>
        If you are using an authorized agent to exercise your rights, we may
        deny a request if the authorized agent does not submit proof that they
        have been validly authorized to act on your behalf.
      </p>
      <h4>Verification process</h4>
      <p>
        We may request that you provide additional information reasonably
        necessary to verify you and your consumer’s request. If you submit the
        request through an authorized agent, we may need to collect additional
        information to verify your identity before processing your request.
      </p>
      <br />
      <p>
        Upon receiving your request, we will respond without undue delay, but in
        all cases, within forty-five (45) days of receipt. The response period
        may be extended once by forty-five (45) additional days when reasonably
        necessary. We will inform you of any such extension within the initial
        45-day response period, together with the reason for the extension.
      </p>

      <h4>Right to appeal</h4>
      <p>
        If we decline to take action regarding your request, we will inform you
        of our decision and reasoning behind it. If you wish to appeal our
        decision, please email us at info@skillset.tv. Within sixty (60) days of
        receipt of an appeal, we will inform you in writing of any action taken
        or not taken in response to the appeal, including a written explanation
        of the reasons for the decisions. If your appeal if denied, you may
        contact the{" "}
        <a
          href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
          target="_blank"
        >
          Attorney General to submit a complaint
        </a>
        .
      </p>

      <h2 id="updates">13. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
      <p>
        <strong>In Short:</strong> Yes, we will update this notice as necessary
        to stay compliant with relevant laws.
      </p>
      <br />
      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated “Revised” date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>

      <h2 id="how-to-contact-us">
        14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </h2>
      <p>
        If you have questions or comments about this notice, you may email us at
        info@skillset.tv or contact us by post at:
      </p>
      <br />
      <p>Skillset Media Ltd</p>
      <p>Sderot Nordau 74</p>
      <p>Tel Aviv 6238122</p>
      <p>Israel</p>

      <h2 id="review-update-delete">
        15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h2>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please visit: info@skillset.tv.
      </p>
    </div>
  );
}
