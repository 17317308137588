import React from "react";
import "./EmptyButton.scss";

export default function EmptyButton({ onClick, text }) {
  return (
    <button className="emptyButtonWrapper" onClick={onClick}>
      <span>{text}</span>
      <div class="marquee" aria-hidden="true">
        <div class="marquee__inner">
          <span>{text}</span>
          <span>{text}</span>
          <span>{text}</span>
          <span>{text}</span>
        </div>
      </div>
    </button>
  );
}
