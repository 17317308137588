import React, { useState, useEffect } from "react";
import "./News.scss";
import { useTranslation } from "react-i18next";
import Text from "../../components/Text/Text";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styled from "styled-components";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { useIsMobile } from "../../components/useIsMobile";
import SmallCard from "../../components/Card/SmallCard/SmallCard";
import video from "../../assets/video/arrows.webm";

// Images
import Alexia from "../../assets/images/alexia.png";
import Pedri from "../../assets/images/pedri.png";
import AgueroVertical from "../../assets/images/agueroVertical.png";
import AgueroHorizontal from "../../assets/images/agueroHorizontal.png";
import ModricVertical from "../../assets/images/modricVertical.png";
import GiorgioVertical from "../../assets/images/giorgioVertical.jpg";
import GiorgioHorizontal from "../../assets/images/giorgioNews.jpg";
import Torres from "../../assets/images/torres.png";
import Bufon from "../../assets/images/buffonNews.jpg";
import buffonMobileNews from "../../assets/images/buffonMobileNews.jpg";
import CarlosVertical from "../../assets/images/carlosVertical.jpg";
import Van from "../../assets/images/van.png";
import Courtois from "../../assets/images/courtois.png";
import LewandowskiVertical from "../../assets/images/lewandowskiVertical.png";
import LewandowskiHorizontal from "../../assets/images/lewandowskiHorizontal.png";
import VerticalCard from "../../components/Card/VerticalCard/VerticalCard";
import HorizontalCard from "../../components/Card/HorizontalCard/HorizontalCard";
import JoinWaitlist from "../../components/Modal/JoinWaitlist/JoinWaitlist";
import Offer from "../../components/Modal/Offer/Offer";
import Success from "../../components/Modal/Success/Success";
import toLeft from "../../assets/images/icons/toLeft.svg";
import toRight from "../../assets/images/icons/toRight.svg";
import { Slide } from "react-awesome-reveal";
import OnScrollTypography from "../../components/Text/OnScrollTypography";
import axios from "axios";

const SliderContainer = styled.div`
  margin-top: 60px;
`;

export default function News() {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();
  const [openJoin, setOpenJoin] = useState(false);
  const [openOffer, setOpenOffer] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const handleGetStarted = () => {
    setOpenJoin(true);
  };

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://stage.skillset.tv/api/newsletter/data",
        {
          headers: {
            Locale: localStorage.getItem("i18nextLng") === "sp" ? "es" : "en",
          },
        }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="newsWrapper" id="exclusiveContent">
        <OnScrollTypography
          smallTitle={t("news.smallTitle")}
          title={t("news.title")}
          description={t("news.description")}
          text={t("news.button")}
          className={"smallButton"}
          desktopStyle={"featureDesktopWrapper"}
          onClick={() => handleGetStarted()}
        />

        {isMobile && (
          <SliderContainer>
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              pagination={{
                type: "fraction",
              }}
              navigation
              loop
              slidesPerView={1}
              spaceBetween={20}
              autoplay={{ delay: 3000 }}
            >
              <SwiperSlide>
                <div className="smallCardsWrapper">
                  <Slide direction="left" cascade triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <SmallCard
                        backgroundImage={Alexia}
                        name={data.lessons[8].player?.name}
                        title={data.lessons[8].name}
                        description={data.lessons[8]?.description}
                        tagTitle={data.lessons[8].skill?.name}
                        tagColor={"#FF4C4C"}
                        tagBackground={"#ff4c4c4d"}
                      />
                    )}
                  </Slide>
                  <Slide direction="right" cascade triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <SmallCard
                        backgroundImage={Pedri}
                        name={data.lessons[10].player?.name}
                        title={data.lessons[10].name}
                        description={data.lessons[10]?.description}
                        tagTitle={data.lessons[10].skill?.name}
                        tagColor={"#00FFD1"}
                        tagBackground={"#00ffd130"}
                      />
                    )}
                  </Slide>
                </div>
              </SwiperSlide>
              {/* <SwiperSlide>
                <div>
                  <Slide direction="down" cascade triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <VerticalCard
                        backgroundImage={AgueroVertical}
                        name={data.lessons[5].player?.name}
                        title={data.lessons[5].name}
                        description={data.lessons[5]?.description}
                        tagTitle={data.lessons[5].skill?.name}
                        tagColor={"#00FFD1"}
                        tagBackground={"#00ffd130"}
                      />
                    )}
                  </Slide>
                </div>
              </SwiperSlide> */}
              {/* <SwiperSlide>
                <div>
                  <Slide direction="up" cascade triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <VerticalCard
                        backgroundImage={ModricVertical}
                        name={data.lessons[0].player?.name}
                        title={data.lessons[0].name}
                        description={data.lessons[0]?.description}
                        tagTitle={data.lessons[0].skill?.name}
                        tagColor={"#15F05F"}
                        tagBackground={"#15f05f25"}
                      />
                    )}
                  </Slide>
                </div>
              </SwiperSlide> */}
              <SwiperSlide>
                <div>
                  <Slide direction="up" cascade triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <VerticalCard
                        backgroundImage={GiorgioVertical}
                        name={data.lessons[2].player?.name}
                        title={data.lessons[2].name}
                        description={data.lessons[2]?.description}
                        tagTitle={data.lessons[2].skill?.name}
                        tagColor={"#00FFD1"}
                        tagBackground={"#00ffd130"}
                      />
                    )}
                  </Slide>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="smallCardsWrapper">
                  <Slide direction="left" cascade triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <SmallCard
                        backgroundImage={buffonMobileNews}
                        name={data.lessons[6].player?.name}
                        title={data.lessons[6].name}
                        description={data.lessons[6]?.description}
                        tagTitle={data.lessons[6].skill?.name}
                        tagColor={"#15F05F"}
                        tagBackground={"#15f05f25"}
                      />
                    )}
                  </Slide>
                  <Slide direction="right" cascade triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <SmallCard
                        backgroundImage={Torres}
                        name={data.lessons[1].player?.name}
                        title={data.lessons[1].name}
                        description={data.lessons[1]?.description}
                        tagTitle={data.lessons[0].skill?.name}
                        tagColor={"#00FFD1"}
                        tagBackground={"#00ffd130"}
                      />
                    )}
                  </Slide>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <Slide direction="down" cascade triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <VerticalCard
                        backgroundImage={CarlosVertical}
                        name={data.lessons[4].player?.name}
                        title={data.lessons[4].name}
                        description={data.lessons[4]?.description}
                        tagTitle={data.lessons[4].skill?.name}
                        tagColor={"#15F05F"}
                        tagBackground={"#15f05f25"}
                      />
                    )}
                  </Slide>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="smallCardsWrapper">
                  <Slide direction="left" cascade triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <SmallCard
                        backgroundImage={Van}
                        name={data.lessons[9].player?.name}
                        title={data.lessons[9].name}
                        description={data.lessons[9]?.description}
                        tagTitle={data.lessons[9].skill?.name}
                        tagColor={"#FF4C4C"}
                        tagBackground={"#ff4c4c4d"}
                      />
                    )}
                  </Slide>
                  <Slide direction="right" cascade triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <SmallCard
                        backgroundImage={Courtois}
                        name={data.lessons[7].player?.name}
                        title={data.lessons[7].name}
                        description={data.lessons[7]?.description}
                        tagTitle={data.lessons[7].skill?.name}
                        tagColor={"#00FFD1"}
                        tagBackground={"#00ffd130"}
                      />
                    )}
                  </Slide>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <Slide direction="up" cascade triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <VerticalCard
                        backgroundImage={LewandowskiVertical}
                        name={data.lessons[3].player?.name}
                        title={data.lessons[3].name}
                        description={data.lessons[3]?.description}
                        tagTitle={data.lessons[3].skill?.name}
                        tagColor={"#00FFD1"}
                        tagBackground={"#00ffd130"}
                      />
                    )}
                  </Slide>
                </div>
              </SwiperSlide>
            </Swiper>
          </SliderContainer>
        )}

        {!isMobile && (
          <SliderContainer>
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              loop
              pagination={{
                type: "fraction",
              }}
              navigation
              slidesPerView={1}
              spaceBetween={20}
              autoplay={{ delay: 9000 }}
            >
              <SwiperSlide>
                <div className="desktopCardsWrapp">
                  <Slide direction="up" triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <SmallCard
                        backgroundImage={Alexia}
                        name={data.lessons[8].player?.name}
                        title={data.lessons[8].name}
                        description={data.lessons[8]?.description}
                        tagTitle={data.lessons[8].skill?.name}
                        tagColor={"#FF4C4C"}
                        tagBackground={"#ff4c4c4d"}
                      />
                    )}
                  </Slide>
                  <Slide direction="down" triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <SmallCard
                        backgroundImage={Pedri}
                        name={data.lessons[10].player?.name}
                        title={data.lessons[10].name}
                        description={data.lessons[10]?.description}
                        tagTitle={data.lessons[10].skill?.name}
                        tagColor={"#00FFD1"}
                        tagBackground={"#00ffd130"}
                      />
                    )}
                  </Slide>
                  <Slide direction="left" triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <HorizontalCard
                        backgroundImage={LewandowskiHorizontal}
                        name={data.lessons[3].player?.name}
                        title={data.lessons[3].name}
                        description={data.lessons[3]?.description}
                        tagTitle={data.lessons[3].skill?.name}
                        tagColor={"#00FFD1"}
                        tagBackground={"#00ffd130"}
                      />
                    )}
                  </Slide>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "30px",
                    }}
                  >
                    <Slide direction="right" triggerOnce>
                      {data?.lessons?.length > 0 && (
                        <SmallCard
                          backgroundImage={Van}
                          name={data.lessons[9].player?.name}
                          title={data.lessons[9].name}
                          description={data.lessons[9]?.description}
                          tagTitle={data.lessons[9].skill?.name}
                          tagColor={"#FF4C4C"}
                          tagBackground={"#ff4c4c4d"}
                        />
                      )}
                    </Slide>
                    <Slide direction="up" triggerOnce>
                      {data?.lessons?.length > 0 && (
                        <SmallCard
                          backgroundImage={Courtois}
                          name={data.lessons[7].player?.name}
                          title={data.lessons[7].name}
                          description={data.lessons[7]?.description}
                          tagTitle={data.lessons[7].skill?.name}
                          tagColor={"#00FFD1"}
                          tagBackground={"#00ffd130"}
                        />
                      )}
                    </Slide>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="desktopCardsReversWrapp">
                  <Slide direction="left" triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <HorizontalCard
                        backgroundImage={GiorgioHorizontal}
                        name={data.lessons[2].player?.name}
                        title={data.lessons[2].name}
                        description={data.lessons[2]?.description}
                        tagTitle={data.lessons[2].skill?.name}
                        tagColor={"#00FFD1"}
                        tagBackground={"#00ffd130"}
                      />
                    )}
                  </Slide>
                  <Slide direction="down" triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <SmallCard
                        backgroundImage={Torres}
                        name={data.lessons[1].player?.name}
                        title={data.lessons[1].name}
                        description={data.lessons[1]?.description}
                        tagTitle={data.lessons[0].skill?.name}
                        tagColor={"#00FFD1"}
                        tagBackground={"#00ffd130"}
                      />
                    )}
                  </Slide>
                  <Slide direction="up" triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <SmallCard
                        backgroundImage={Bufon}
                        name={data.lessons[6].player?.name}
                        title={data.lessons[6].name}
                        description={data.lessons[6]?.description}
                        tagTitle={data.lessons[6].skill?.name}
                        tagColor={"#15F05F"}
                        tagBackground={"#15f05f25"}
                      />
                    )}
                  </Slide>
                  <Slide direction="down" triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <VerticalCard
                        backgroundImage={CarlosVertical}
                        name={data.lessons[4].player?.name}
                        title={data.lessons[4].name}
                        description={data.lessons[4]?.description}
                        tagTitle={data.lessons[4].skill?.name}
                        tagColor={"#15F05F"}
                        tagBackground={"#15f05f25"}
                      />
                    )}
                  </Slide>
                </div>
              </SwiperSlide>

              {/* <SwiperSlide>
                <div className="desktopCardsWrapp">
                  <Slide direction="up" triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <SmallCard
                        backgroundImage={Van}
                        name={data.lessons[9].player?.name}
                        title={data.lessons[9].name}
                        description={data.lessons[9]?.description}
                        tagTitle={data.lessons[9].skill?.name}
                        tagColor={"#FF4C4C"}
                        tagBackground={"#ff4c4c4d"}
                      />
                    )}
                  </Slide>
                  <Slide direction="down" triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <SmallCard
                        backgroundImage={Courtois}
                        name={data.lessons[7].player?.name}
                        title={data.lessons[7].name}
                        description={data.lessons[7]?.description}
                        tagTitle={data.lessons[7].skill?.name}
                        tagColor={"#00FFD1"}
                        tagBackground={"#00ffd130"}
                      />
                    )}
                  </Slide>
                  <Slide direction="left" triggerOnce>
                    {data?.lessons?.length > 0 && (
                      <HorizontalCard
                        backgroundImage={LewandowskiHorizontal}
                        name={data.lessons[3].player?.name}
                        title={data.lessons[3].name}
                        description={data.lessons[3]?.description}
                        tagTitle={data.lessons[3].skill?.name}
                        tagColor={"#00FFD1"}
                        tagBackground={"#00ffd130"}
                      />
                    )}
                  </Slide>
                  <div className="verticalContent">
                    <Slide direction="right" triggerOnce>
                      <video
                        style={{
                          width: "100%",
                        }}
                        autoPlay
                        muted
                        loop
                        playsInline
                      >
                        <source src={video} type="video/webm" />
                        Your browser does not support the video tag.
                      </video>
                    </Slide>
                    <Slide direction="left" triggerOnce>
                      <video
                        style={{
                          width: "100%",
                          transform: "rotate(180deg)",
                        }}
                        autoPlay
                        muted
                        loop
                        playsInline
                      >
                        <source src={video} type="video/webm" />
                        Your browser does not support the video tag.
                      </video>
                    </Slide>
                  </div>
                </div>
              </SwiperSlide> */}
            </Swiper>
          </SliderContainer>
        )}
      </div>

      {openJoin && (
        <JoinWaitlist
          onClick={() => setOpenJoin(false)}
          setOpenJoin={setOpenJoin}
          setOpenOffer={setOpenOffer}
        />
      )}
      {openOffer && (
        <Offer
          setOpenOffer={setOpenOffer}
          setOpenSuccess={setOpenSuccess}
          onClick={() => setOpenOffer(false)}
        />
      )}
      {openSuccess && <Success setOpenSuccess={setOpenSuccess} />}
    </>
  );
}
