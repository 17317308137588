import React from "react";
import FullButton from "../Button/FullButton/FullButton";
import "./Text.scss";

export default function Text({
  smallTitle,
  title,
  description,
  text,
  className,
  desktopStyle,
  bigTitle,
  onClick,
}) {
  return (
    <div className={`textWrapper ${desktopStyle}`}>
      <>
        {bigTitle ? (
          <h3>{smallTitle}</h3>
        ) : (
          <p className="smallTitle">{smallTitle}</p>
        )}
        {bigTitle ? <h1>{title}</h1> : <h2>{title}</h2>}

        <p>{description}</p>
      </>

      <FullButton text={text} className={className} onClick={onClick} />
    </div>
  );
}
