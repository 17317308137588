import React from "react";
import "./Tags.scss";

export default function Tags({ color, backgroundColor, title, number }) {
  return (
    <div
      className="tagWrapper"
      style={{ color: color, backgroundColor: backgroundColor }}
    >
      {number} {title}
    </div>
  );
}
