import React, { useState } from "react";

import "./Header.scss";

// Images
import Logo from "../../assets/images/icons/logo.svg";
import Hamburger from "../../assets/images/icons/hamburger.svg";
import Close from "../../assets/images/icons/close.svg";
import English from "../../assets/images/icons/english_flag.svg";
import Spanish from "../../assets/images/icons/spanish_flag.svg";

// Components
import Flag from "../../components/Flag/Flag";
import { useIsMobile } from "../../components/useIsMobile";
import { useTranslation } from "react-i18next";
import FullButton from "../../components/Button/FullButton/FullButton";
import EmptyButton from "../../components/Button/EmptyButton/EmptyButton";
import Dropdown from "../../components/Dropdown/Dropdown";
import JoinWaitlist from "../../components/Modal/JoinWaitlist/JoinWaitlist";
import Offer from "../../components/Modal/Offer/Offer";
import Success from "../../components/Modal/Success/Success";

export default function Header() {
  const isMobile = useIsMobile();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en" // Default language set to "en"
  );
  const { t, i18n } = useTranslation();
  const [openJoin, setOpenJoin] = useState(false);
  const [openOffer, setOpenOffer] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  // Function to change language
  const handleChangeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
    localStorage.setItem("i18nextLng", newLanguage);
   window.location.reload();
  };

  // Function to handle "Get Started" action
  const handleGetStarted = () => {
    setOpenSidebar(false);
    setOpenJoin(true);
  };

  return (
    <>
      {isMobile ? (
        <div className="mobileHeaderWrapper">
          <img src={Logo} alt="Logo" className="logo"/>
          <img
            src={Hamburger}
            alt="Hamburger"
            onClick={() => setOpenSidebar(true)}
          />
        </div>
      ) : (
        <div className="desktopHeaderWrapper">
          <div className="menuContent">
            <img src={Logo} alt="Logo" style={{ cursor: "pointer" }} className="logo"/>
            <ul>
              <li>
                <a href="#pro-players">{t("navigation.proPlayers")}</a>
              </li>
              <li>
                <a href="/#exclusiveContent">
                  {t("navigation.exclusiveContent")}
                </a>
              </li>
              <li>
                <a href="/#aboutApp">{t("navigation.aboutTheApp")}</a>
              </li>
              <li>
                <a href="/#challenges">{t("navigation.challenges")}</a>
              </li>
            </ul>
          </div>

          <div className="menuActions">
            <EmptyButton
              text={t("navigation.getStarted")}
              onClick={() => handleGetStarted()}
            />
            <Dropdown />
          </div>
        </div>
      )}

      {openSidebar && (
        <div className="overlay">
          <div className="sidebar">
            <div className="sidebarTop">
              <div className="sidebarTopActions">
                <img src={Logo} alt="Logo" className="logo" />
                <img
                  src={Close}
                  alt="Close"
                  className="closeIcon"
                  onClick={() => setOpenSidebar(false)}
                />
              </div>

              <div className="menuList">
                <ul>
                  <li>
                    <a
                      href="#pro-players"
                      onClick={() => setOpenSidebar(false)}
                    >
                      {t("navigation.proPlayers")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#exclusiveContent"
                      onClick={() => setOpenSidebar(false)}
                    >
                      {t("navigation.exclusiveContent")}
                    </a>
                  </li>
                  <li>
                    <a href="/#aboutApp" onClick={() => setOpenSidebar(false)}>
                      {t("navigation.aboutTheApp")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#challenges"
                      onClick={() => setOpenSidebar(false)}
                    >
                      {t("navigation.challenges")}
                    </a>
                  </li>
                </ul>
                <EmptyButton
                  text={t("navigation.getStarted")}
                  onClick={() => handleGetStarted()}
                />
              </div>
            </div>

            <div className="sidebarBottom">
              <Flag
                source={English}
                flag={t("flag.english")}
                onClick={() => handleChangeLanguage("en")}
                isActive={currentLanguage === "en"}
              />
              <span className="line" />
              <Flag
                source={Spanish}
                flag={t("flag.spanish")}
                onClick={() => handleChangeLanguage("sp")}
                isActive={currentLanguage === "sp"}
              />
            </div>
          </div>
        </div>
      )}

      {openJoin && (
        <JoinWaitlist
          onClick={() => setOpenJoin(false)}
          setOpenJoin={setOpenJoin}
          setOpenOffer={setOpenOffer}
        />
      )}
      {openOffer && (
        <Offer
          setOpenOffer={setOpenOffer}
          setOpenSuccess={setOpenSuccess}
          onClick={() => setOpenOffer(false)}
        />
      )}
      {openSuccess && <Success setOpenSuccess={setOpenSuccess} />}
    </>
  );
}
