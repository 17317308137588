import React from "react";
import "./Radius.scss";

export default function Radius({ title, isActive, onClick }) {
  return (
    <button
      className={`radiusWrapper ${isActive ? "active" : ""}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
}
